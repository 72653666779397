

.mt-12
{
    width: 100%;
    margin: 0 auto;
}

.member-container{
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    background-size: cover;
    /* background: url("../../assets/footer-bg.jpg") no-repeat center; */
    
}

section h2{
    color: red;
}

.member-container #security-privacy{
    margin-left: 30px;
    height: 200px;
    width: 40%;
}

#membership-billing{
    height: 200px;
    width: 40%;
}

#membership-billing .signout-btn{
    width: 100px;
    border: none;
    height: 40px;
    border-radius: 10px;
    background-color: red;
    color: white;
    transition: .3s ease ;
}

#membership-billing .signout-btn:hover{
    transform: translateY(-5px);
    box-shadow: 0px 20px 40px rgba(255, 0, 25, 0.365);
  }

.lost-pass button {
    color : lightcoral;
    background-color: transparent;
    border: none;
    margin-left: 5px;
    text-decoration: underline;
}

.lost-pass button:hover {
    color : red;
    background-color: transparent;
    border: none;
    margin-left: 5px;
    text-decoration: underline;
}

.reset-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .reset-dialog-content {
    background: white;
    padding: 20px;
    color: black;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .error-message {
    color: red;
    margin-top: 10px;
  }

  .reset-dialog-content button{
    width: 100px;
    border: none;
    height: 40px;
    border-radius: 10px;
    margin: 10px;
    background-color: red;
    color: white;
    transition: .3s ease ;
  }

  .reset-dialog-content button:hover{
    transform: translateY(-5px);
    box-shadow: 0px 20px 40px rgba(255, 0, 25, 0.365);
  }
  

@media screen and(max-width: 768px) {
    
}

