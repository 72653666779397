@use "../../scss/" as *;

.logo {
    font-size: 2.5rem;
    font-weight: 600;
  
    @include flex(center, unset);

    img {
        margin-right: 10px;
        width: 60px;
        margin-bottom: 10px;

        @include mobile {
          
            width: 25px;
            margin-right: 0;
            margin-bottom: 5px;
        }
    }

    @include mobile {
          
     font-size: 2rem;
  }
}

.header {
    height: $header-height;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    transition: height 0.3s ease, background-color 0.3s ease;

    &.shrink {
        height: $header-shrink-height;
        background-color: $body-bg;
       
    }

    &__wrap {
        @include flex(center, space-between);
        height: 100%;
        padding: 0 2rem;
        display: flex;
     

        @include mobile {
            justify-content: center;
        }
    }

    select .options {
        color: black;
    }

  

    &__nav {
        @include flex(start, unset);
        display: flex;
        margin-top: 15px;        

        & > * ~ * {
            margin-left: 2rem;
        }

        li {
            
            position: relative;
            font-size: 1rem;  
            font-size: 22px;
            display: flex;

            transition: color 0.3s ease; /* Smooth color change transition */

            &:hover,
            &.active {
                color: red; /* Change the text color to red for the active state */
                
                &::after {
                    width: 100%; /* Keep the underline effect for hover if desired */
                }

                .dropdown-menu {
                    display: block;
                }

                // Show subcategories on hover
                .subcategories {
                    display: block;
                }
            }

            &::after {
                
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                transition: width 0.5s ease;
                height: 2px;
                color: $main-color;
                background-color: $main-color;
            }
        }

        a, .nav-link, .small {
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }

        @include mobile {
            position: fixed;
            left: 0;
            bottom: 0;
            margin-bottom: -5px;
            height: 5rem;
            width: 100%;
            background-color: #000; // Dark background
            padding: 0 2rem;
            box-shadow: $box-shadow;

            @include flex(center, space-between);

            & > * ~ * {
                margin-left: -1px;
            }
        }
    }
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.656);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    height: 50vh; /* Fixed height for the modal content */
    overflow: hidden; /* Prevent the entire content from scrolling */
    color: #fff;
    background-color: #0f0f0f;
    padding: 20px;
    border-radius: 8px;
    width: 40%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    @include mobile{
      width: 90%;
    }
  }
  
  .genre-header {
    position: sticky;
    top: 0; /* Keeps the header at the top of its container */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: 0f0f0f;
    padding: 10px;
    z-index: 10;
    width: 100%; /* Make sure the header takes up the full width of its container */
  }
  
  .genre-list {
    list-style: none;
    padding: 0;
    margin: 0;
    height: calc(50vh - 80px); /* Ensure the list doesn't exceed the available space */
    overflow-y: auto; /* Make the genre list scrollable */
  }
  
  .genre-list li {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .genre-list li:hover {
    background-color: #9e0000;
  }
  
  .close-modal {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .search-close-modal {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 24px;
    cursor: pointer;
  }
  
  
  

  .search-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.656);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of other elements */
}


.search-modal-content {
  background: #0f0f0f;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 400px;
}

.search-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.search-modal-content h3 {
  margin-bottom: 15px;
}

.search-close-modal{
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    margin-top: -10px;
}
.search-close-modal i{
    font-size: 40px;
   
}

.search-modal-content input {
  width: 100%;
  padding: 10px;
  background-color: #1f1f1f;
  margin-bottom: 2px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.search-modal-content select {
  padding: 10px;
  margin-bottom: 15px;
  background-color: rgb(39, 39, 39);
  color: white;
  border-radius: 5px;
  width: 100%;
}

.search-modal-content .search-submit {
  padding: 10px 20px;
  background-color: $main-color;
  color: white;
  width: 100%;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}



.search-modal-content button:hover {
  opacity: 0.9;
}



.suggestions{
  height: 300px;
  overflow: scroll;
  overflow-y: auto;
  background: black;
  width: 100%;
  
}

.suggestions::-webkit-scrollbar{
  display: none;
}

.show-container{
  display: flex;
 ;
}

.suggestions .show-container img {
  height: 100px;
  margin-right: 20px;
  min-width:  70px;
  margin-bottom: 5px;
}
.suggestion-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background: #f0f0f0;
  }
}

.suggestion-image {
  width: 50px;
  height: auto;
  margin-left: 10px;
}

.suggestion-title{
  font-style: bold;
  font-size: 20px;
  display: -webkit-box;
  color: red;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.suggestion-overview {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-size: 12px;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}