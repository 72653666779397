@use "../../scss/" as *;

button {
    cursor: pointer;
    font-family: $font-family;
    
}

button .icon-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
}

button .icon-container h5 {
    margin-bottom: -2px;
}

.btn {
    border: 4px solid transparent;
    background: $main-color;
    color: $white;
    border-radius: $border-radius;
    padding: 0.5rem 1.8rem;
    font-size: 1.1rem;
    font-weight: 500;
    transition: all 0.5s ease;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.btn i{
    font-size: 20px;
    padding: 0;
}

.btn:hover {
    background: $main-color;
    color: #fff;
    transform: translateY(-5px);
    box-shadow: 0px 20px 40px rgba(255, 0, 25, 0.365); 
}

.btn-play {
    border: 4px solid transparent;
    background: white;
    color: $black;
    border-radius: 5px;
    padding: 0.2rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    width: 200px;
    transition: all 0.5s ease;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    font-family: $font-family;
}


.btn-play  i {
    font-size: 35px;
    padding: 0;
    margin-left: -10px;
}

.btn-play:hover {
    background: $main-color;
    color: #fff;
    transform: translateY(-5px);
    box-shadow: 0px 20px 40px rgba(255, 0, 25, 0.365); 
}

.btn-outline {
    border: 4px solid transparent;
    background: gray;
    color: $white;
    border-radius: 5px;
    padding: 0.2rem 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    width: 200px;
    transition: all 0.5s ease;
    position: relative;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
}

.btn-outline:hover {
    transform: translateY(-5px);
    box-shadow: 0px 20px 40px rgba(255, 0, 25, 0.365); 
    color: $white;
    background-color: $main-color;
}

.btn-view {
    background-color: transparent;
    color: $white;
    border: none;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px; 
    transition: color 0.3s ease;

}
.btn-view:hover{
 color: $main-color;
}

.btn.small {
    border-width: 2px;
    padding: 0.25rem 1.5rem;
    font-size: 1rem;
    border-radius: 10px;
    background-color: red;
}

.btn.small:hover {
    box-shadow: 0px 20px 40px rgba(255, 0, 25, 0.365);
    color: white;
    background-color: red;
}

/* Mobile View Adjustment */
@media (max-width: 768px) {
    
    .btn-play {
        height: 40px;
        width: 150px;
    }

    .btn-play i {
        font-size: 25px; /* Adjust icon size for mobile */
    }

    .btn-outline {
        height: 40px; /* Smaller height for outline button */
        width: 150px;
    }

    /* Optionally, you can apply the 'small' class automatically for mobile */
    .btn.small {
        padding: 0.25rem 1rem;
        font-size: 0.9rem; /* Font size for small buttons on mobile */
        width: 140px;
        height: 35px; /* Adjust size of small buttons */
    }
}

@media (max-width: 468px) {
    
    .btn {
        height: 40px;
        width: 120px;
    }

    .btn i {
        font-size: 22px; /* Adjust icon size for mobile */
    }

    .btn-outline {
        height: 40px; /* Smaller height for outline button */
    }

    /* Optionally, you can apply the 'small' class automatically for mobile */
    .btn.small {
        padding: 0.25rem 1rem;
        font-size: 0.9rem; /* Font size for small buttons on mobile */
        width: 80px;
        height: 24px; /* Adjust size of small buttons */
    }
}
