@use "../../scss/" as *;

.banner-image {
  width: 100%;
  height: 400px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 5px;
  z-index: 1; /* Background and gradient have lower z-index */

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  
  }



  // Media query for smaller screens
  @media (max-width: 768px) {
    height: 300px; // Smaller height for mobile
  }

  // Media query for even smaller screens (like phones in portrait mode)
  @media (max-width: 480px) {
    height: 250px; // Even smaller height
  }
}

.banner-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  color: white;
  height: 100%;
  position: absolute;
  padding: 30px; /* Reduced padding for smaller screen */
  background-color: rgba(255, 0, 0, 0);
  border-radius: 5px;

  // Stack the content vertically on smaller screens

}

.details-container {
  margin-right: 30px;
  // Responsive adjustments

  
}

.poster {
  width: 180px; /* Reduce the width of the poster */
  height: auto;
  object-fit: cover; /* Ensure the image fills its container */
  border-radius: 5px;

  // Make the poster responsive
  @media (max-width: 768px) {
    width: 140px; // Further reduce width on smaller screens
  }

  @media (max-width: 480px) {
    width: 120px; // Further reduce width on very small screens
    display: none;
  }
}

h1.title {
  font-size: 20px; /* Smaller font size for the title */
  margin-bottom: 10px;

  // Adjust font size for smaller screens
  @media (max-width: 768px) {
    font-size: 18px; // Smaller font size for tablet screens
  }

  @media (max-width: 480px) {
    font-size: 16px; // Further reduce font size for phones
  }
}

p.description {
  font-size: 14px; /* Smaller font size for the description */
  margin-bottom: 20px;

  // Adjust font size for smaller screens
  @media (max-width: 768px) {
    font-size: 12px; // Smaller font size for tablet screens
  }

  @media (max-width: 548px) {
    font-size: 10px; // Further reduce font size for phones
  }
}

.watch-now-button {
  background-color: #ff0000;
  color: white;
  padding: 8px 16px; /* Adjusted padding for smaller screens */
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    background-color: #cc0000;
  }

  // Make the button responsive
  @media (max-width: 768px) {
    padding: 6px 12px; // Reduced padding for smaller screens
  }

  @media (max-width: 480px) {
    padding: 4px 8px; // Further reduced padding for very small screens
  }
}
