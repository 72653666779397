.comment-section {
    padding: 20px;
    background-color: #000000;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .comments-list {
    margin-bottom: 10px;
    max-height: 400px; /* Maximum height for the comments section */
    overflow-y: auto; /* Allows vertical scrolling when content exceeds max-height */
  }
  
  .comment-item {
    color: white;
    padding: 8px; /* Reduced padding between each comment */
    margin-bottom: 6px; /* Reduced margin between comments */
  }
  
  .comment-user {
    display: flex;
    align-items: center;
  }
  
  .comment-text {
    margin: 0 50px 5px; /* Reduced bottom margin */
  }
  
  .commenter-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .commenterName {
    font-weight: bold;
  }
  
  .comment-timestamp {
    font-size: 0.8em;
    margin-top: 2px;
    color: #555;
  }
  
  .comment-form {
    display: flex;
    flex-direction: column;
  }
  
  .comment-form textarea {
    padding: 10px;
    font-size: 1em;
    resize: none;
    color: white;
    border: 1px solid #000000;
    border-radius: 8px;
    background-color: rgb(37, 37, 37);
    margin-bottom: 10px;
  }
  
  .submit-comment-btn {
    background-color: red;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .submit-comment-btn:hover {
    background-color: rgba(255, 0, 0, 0.664);
  }
  
  .signin-prompt {
    margin-top: 10px;
    font-size: 14px;
  }
  
  .signin-prompt a {
    color: red;
    text-decoration: underline;
  }
  
  .comment-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%; /* Makes the avatar circular */
    margin-right: 10px;
  }
  
  .delete-comment-btn {
    background-color: transparent;
    color: red;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    margin-top: 5px;
  }
  
  .delete-comment-btn:hover {
    color: rgba(255, 0, 0, 0.702);
  }
  