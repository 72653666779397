@use "../../scss/breakpoint" as *;

.live-list {
    .swiper-slide {
        width: 20%;
        height: 10%;

        @include tablet {
            width: 30%;
        }

        @include mobile {
            width: 40%;
        }
    }
}
