  .privacy-policy-section {
    margin-top: 3.5rem;
    padding: 60px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .privacy-policy-container {
    max-width: 900px;
    background: var(--accent-color);
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .privacy-policy-title {
    font-size: 2.8rem;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 20px;
    text-align: center;
  }
  
  .privacy-policy-description {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 30px;
  }
  


  
  /* Responsive Design */
  @media (max-width: 768px) {
    .privacy-policy-title {
      font-size: 2rem;
    }
  
    p, ul {
      font-size: 0.9rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  }
  