@use "../../scss/" as *;

.hero-slide {
 
  
}


.hero-slide__item {
    padding: 9rem 0;
    width: 100%;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @include overlay();
    }

    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        background-image: linear-gradient(to top, $body-bg, rgba($black, 0));
    }

    &__content {
        @include flex(center, center);
        position: relative;

        &__info {
            width: 55%;
            padding: 0 3rem;
            position: relative;

            @include tablet {
                width: 100%;
            }

            & > * ~ * {
                margin-top: 2rem;
            }

            .title {
                font-size: 5rem;
                font-weight: 700;
                line-height: 1;                
               font-family: $font-family;

                @include tablet {
                    font-size: 4rem;
                }
                @include mobile{
                    font-size: 2rem;
                    text-align: center;
                }
            }

            .overview {
                font-weight: 500;
               font-family: $font-family;
                @include mobile{
                    text-align: center;
                }
            }

            .other-details{
                @include mobile{
                    text-align: center;
                }
            }

            .year {
                color: #d5d5d5;
               font-family: $font-family;
            }

            .vote{
               font-family: $font-family;
                color: yellow;
            }
            .genres{
                color: #d5d5d5;
                margin-top: 1px;
               font-family: $font-family;
            }

            .vote-section{
              margin-top: 1px;
              font-family: $font-family;
              gap: 5px;
              color: #d5d5d5;
              display: flex;
              @include mobile{
                justify-content: center;
                text-align: center;
            }
            }


            .btn-container{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                  
                @include mobile{
                    justify-content: center;
                    text-align: center;
                
                }
            }
            .btns > * ~ * {
                margin-left: 1rem;
                justify-content: center;
                
            }

            .btns,
            .title,
            .year,
            .vote,
            .runtime,
            .genres,
            .overview {
                opacity: 0;
                transform: translateY(-100px);
                transition: transform 0.5s ease, opacity 0.5s ease;
            }
        }

        &__poster {
            flex: 1;
            @include flex(center, flex-start);
            position: relative;

            img {
                width: 400px;
                border-radius: $border-radius;
                box-shadow: $box-shadow;

                transform: scale(0);
                transition: transform 0.7s ease;
            }

            @include tablet {
                display: none;
            }
        }
    }

    &.active > &__content > &__content__poster {
        img {
            transform: scale(1);
        }
    }

    &.active > &__content > &__content__info {
        .btns,
        .title,
        .year,
        .vote,
        .runtime,
        .genres,
        .overview {
            opacity: 1;
            transform: translateY(0);
        }

        .title {
            transition-delay: 0.3s, 0.3s;
        }

        .overview, .year,.runtime,.genres,
        .vote {
            transition-delay: 0.6s, 0.6s;
        }


        .btns {
            transition-delay: 0.9s, 0.9s;
        }


    }
}
