@use "../../scss/" as *;

.live-card {
    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    padding-top: 60%;
    padding-left: 100%;
    border-radius: 5px;
    margin-bottom: 1rem;
    transition: .3s ease;

    &:hover {
        transform: translateY(-5px);
    }

    .live-dot {
        position: absolute;
        top: 10px;
        left: 10px;
        width: 8px;
        height: 8px;
        background-color: red;
        border-radius: 50%;
        z-index: 10;
    }

    .pulse-dot {
        position: absolute;
        top: 9px;
        left: 9px;/* Adjust as needed to position beneath the main dot */
        width: 10px; /* Larger size than the main dot */
        height: 10px; /* Larger size than the main dot */
        background-color: rgba(242, 75, 75, 0.527);
        border-radius: 50%;
        opacity: 0; /* Initially hidden */
        animation: pulse 2s infinite;
        z-index: 9; /* Ensure it's behind the main dot */
    }
    

    @keyframes pulse {
        0% {
            transform: scale(1);
            opacity: 0;
        }
        50% {
            transform: scale(1.5);
            opacity: 1;
        }
        100% {
            transform: scale(1);
            opacity: 0;
        }
    }


    
    @media (hover: hover) and (pointer: fine) {
        &:hover::before {
            opacity: 0.8;
        }

        &:hover .btn {
            transform: translate(-50%, -50%) scale(1);
        }
    }
}
