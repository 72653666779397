@use "../../scss/" as *;

.movie-card {
    position: relative;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 160%;
    border-radius: 5px;
    margin-bottom: 1rem;
    transition: .3s ease ;



    .btn {
        position: absolute;
        top: 50%;
        left: 50%;
        background-color: transparent;
        transform: translate(-50%, -50%) scale(0);
        box-shadow: unset;

        border: none;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
    }

   
    .release-year {
        position: absolute;
        top: 10px;
        left: 10px;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        z-index: 1;
        
      }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $black;
        opacity: 0;
        transition: opacity 0.3s ease;
        border-radius: 5px;

    }

    .ratings{
        position: absolute;
        display: flex;
        justify-content: baseline;
        text-align: baseline;
        align-items: baseline;
        bottom: 2px;
        right: 10px;
        gap: 2px;
        color: #ffea00;
        font-size: 14px;
        font-weight: bold;
        z-index: 1; 
        font-size: 15px;
    }

    .views{
        position: absolute;
        display: flex;
        justify-content: baseline;
        text-align: baseline;
        align-items: baseline;
        bottom: 2px;
        left: 10px;
        gap: 2px;
        font-size: 10px;
        font-weight: bold;
        z-index: 1; 
        font-size: 15px;
    }

    .views .eye{
       font-size: 10px;
      
    }

  

    @media (hover: hover) and (pointer: fine) {
        &:hover::before {
            opacity: 0.8;
           
            
        }

        &:hover .btn {
            transform: translate(-50%, -50%) scale(1);
            
        }


      
    }
}





.movie-card:hover{
    transform: translateY(-5px);
   
}


