@charset "utf-8";
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
html {
  position: relative;
}


 #watch-player iframe #wrapper{
  background-color: red;
  color: red;
 }


 .player-container {
  display: flex;
}

.server-buttons{
  margin-top: 20px;
  justify-content: center;
  align-items: center;
  display: flex;

}


.server-buttons button{
  border: 4px solid transparent;
  background: red;
  color: white;
  border-radius:10px;
  margin: 5px;
  padding: 0.2rem 1.2rem;
  font-size: 1.1rem;
  font-weight: 500;
  transition: all 0.5s ease;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.server-buttons button:hover{

  color: #fff;
  transform: translateY(-5px);
  box-shadow: 0px 20px 40px rgba(255, 0, 25, 0.365);
}

.keywords{
  margin-right: 5px;
  font-size: 10px;
}

.dropdown-container {
  overflow-x: scroll;
  margin-left: 20px; /* Space between dropdown and player */
  background-color: black;
  border-radius: 5px;
  width: 310px;
  height: 590px;
  padding: 5px;
}

.dropdown-container::-webkit-scrollbar{
  display: none;
}



.season-dropdown{
  width: 300px;
  height: 40px;
  background-color: transparent;
  color: white;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid gray;
  margin-bottom: 10px;
  outline: none;
}



.ep-dropdown-ul{
  list-style: none;
  margin-left: 0;
  justify-content: start;
}

.ep-dropdown-li{
  width: 100%;
  margin-left: -30px;

}

.ep-dropdown-li button {
  width: 100%;
  height: 60px;
  border: none;
  color: white;
  border-bottom: 1px solid gray;

  cursor: pointer;
}

.ep-dropdown-li button:hover {
  background-color: #444; /* Optional: Highlight button on hover */
}

.active-episode {
  background-color: #b20000;  /* Active episode button color */
  color: white;
}

.inactive-episode {
  background-color: transparent; /* Different color for inactive episodes */
  color: lightgray;
}


.ep-dropdown-li button:hover{

  background-color: rgba(157, 0, 0, 0.611);
}

.watching_player {
  flex: 1;
}

.btn-radius {
  border-radius: 30px;
}
.btn-focus,
.btn-primary {
  background: #3e8afa !important;
  color: #fff !important;
  border-color: #3e8afa !important;
}
.btn-focus:hover,
.btn-primary:hover {
  background: #2160bc !important;
  border-color: #2160bc !important;
}
.btn-secondary {
  background: #eee;
  border-color: #eee;
  color: #111;
}
.btn-light {
  background: #e2e6ea;
  border-color: #e2e6ea;
}
.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #666;
  display: inline-block;
  margin: 3px 10px;
}
.h2-heading {
  font-size: 2em !important;
  margin-bottom: 0;
}
.iframe16x9 {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
}
.iframe16x9 iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 100% !important;
}
#search,
#sidebar_menu,
.film-poster-ahref i,
.film-poster-ahref:after,
.film-poster-ahref:before,
.film-poster-img,
.search-content input.search-input {
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  -moz-transition: all 0.5s ease 0s;
}
.film-poster-ahref:after {
  transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
}
.block_area,
.film-poster,
.nav-item,
.nav-item > a,
.search-content input.search-input {
  position: relative;
}
.film-poster {
  width: 100%;
  padding-bottom: 148%;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.film-poster .film-poster-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  min-height: 100%;
}
.highlight-text {
  color: #3e8afa;
}
#wrapper {
  width: 100%;
  position: relative;
}
#header {
  height: 65px;
  background: #144184;
  margin-bottom: 25px;
  position: relative;
  z-index: 99;
}
#header .container {
  position: relative;
}
#header #logo {
  display: inline-block;
  height: 35px;
  margin: 15px 30px 15px 0;
  float: left;
}
#header #logo img {
  height: 100%;
  width: auto;
  margin-right: 10px;
  float: left;
}
#header #logo span {
  line-height: 35px;
  font-size: 20px;
  font-weight: 600;
  color: #fff !important;
}
.header_right-user.logged {
  margin-top: 4px;
}
.header_right-user.logged > .dropdown > .btn-primary {
  line-height: 30px;
  border-radius: 40px;
  padding: 3px 15px;
}
#mobile_menu {
  padding: 0;
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: 60px;
  left: auto;
  z-index: 4;
  display: none;
}
#mobile_menu.active i {
  color: #3e8afa;
}
#mobile_menu i {
  margin: 0;
  font-size: 18px;
}
#header_menu {
  height: 65px;
  margin: 0;
  float: left;
}
#header_menu .container {
  position: relative;
}
#header_menu ul.header_menu-list {
  list-style: none;
  padding: 0;
  display: block;
}
#header_menu ul.header_menu-list .nav-item {
  display: inline-block;
  margin: 0 3px;
  position: relative;
}
#header_menu ul.header_menu-list .nav-item > a {
  height: 65px;
  line-height: 65px;
  margin: 0;
  padding: 0 20px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}
#header_menu ul.header_menu-list .nav-item:hover > a {
  color: #fff;
}
#header_menu ul.header_menu-list .nav-item.active > a:before {
  content: "";
  width: 100%;
  height: 3px;
  background: #3e8afa;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
#header_menu .header_menu-sub {
  position: absolute;
  top: 65px;
  left: 0;
  width: 700px;
  background: #fff;
  z-index: 102;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}
#header_menu .header_menu-sub ul.sub-menu {
  padding: 10px;
  text-align: left;
  list-style: none;
  overflow: hidden;
  margin-left: -1%;
  margin-right: -1%;
}
#header_menu .header_menu-sub ul.sub-menu li {
  width: 23%;
  float: left;
  margin: 0 1%;
}
#header_menu .header_menu-sub ul.sub-menu li a {
  height: 30px;
  color: #111;
  line-height: 30px;
  padding: 0 10px;
  display: block;
  font-size: 15px;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
#header_menu .header_menu-sub ul.sub-menu li:hover a {
  background: #3e8afa;
  color: #fff !important;
}
#header_right {
  position: absolute;
  top: 10px;
  right: 15px;
}
#header_right #search-toggle {
  display: inline-block;
}
#user-slot {
  display: inline-block;
  height: 45px;
  position: relative;
  float: right;
}
#header .btn-on-header {
  height: 45px;
  border-radius: 3px;
  padding: 0 15px;
  line-height: 45px;
  margin: 0;
  color: #fff;
  background: 0 0;
  box-shadow: none !important;
}
#header .btn-on-header i {
  font-size: 20px;
}
#header .btn-on-header.btn-login {
  font-size: 0;
}
#header .btn-on-header.btn-login i {
  margin-right: 0 !important;
  font-size: 20px;
}
#header.active {
  z-index: 102;
}
#header .btn-on-header:hover {
  background: 0 0;
  color: #3e8afa !important;
}
#header #search-toggle .btn-on-header {
  font-size: 20px;
}
#header #search-toggle.active .btn-on-header {
  background: #3e8afa;
  color: #fff;
}
.header_right-user.logged .dropdown-menu {
  left: auto;
  right: 0;
  top: 110%;
  font-size: 14px;
}
.header_right-user.logged .dropdown-menu .dropdown-item {
  padding: 7px 20px;
}
#search {
  width: 300px;
  margin: 3.5px 10px 0 0;
  color: #111;
  position: relative;
  float: left;
}
.search-content {
  position: relative;
}
.search-content input.search-input {
  height: 38px;
  color: #111;
  padding-left: 40px;
  font-size: 14px;
  font-weight: 300;
  background: #fff;
  border-radius: 30px;
  border: none;
  box-shadow: none !important;
}
.search-content input.search-input:focus {
  background: #fff;
}
.search-content .search-icon,
.search-content .search-submit {
  width: 40px;
  height: 38px;
  line-height: 38px;
  display: inline-block;
  color: #111;
  font-size: 14px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}
.search-content .search-submit {
  left: auto;
  right: 0;
  display: none;
}
.search-content .search-submit i {
  line-height: 40px;
  color: #111;
  font-size: 20px;
}
.search-content .search-content {
  position: relative;
}
.search-content .search-result-pop {
  background: #fff;
  color: #111;
  position: absolute;
  left: 0;
  top: 50px;
  right: 0;
  z-index: 6;
  list-style: none;
  display: none;
}
.search-content .search-result-pop.active {
  display: block;
}
.search-content .search-result-pop .nav-item {
  display: block;
  text-align: left;
  color: #555;
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}
.search-content .search-result-pop .nav-item:hover {
  background: #eee;
}
.search-content .search-result-pop .nav-item .film-poster {
  float: left;
  width: 50px;
  padding-bottom: 70px;
}
.search-content .search-result-pop .nav-item .srp-detail {
  padding-left: 65px;
  padding-top: 0.7rem;
  font-size: 12px;
}
.search-content .search-result-pop .nav-item .srp-detail .film-name {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2em;
  max-width: 100%;
  margin-bottom: 8px;
  height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #111;
}
.search-content .search-result-pop .nav-bottom {
  padding: 15px;
  background: #3e8afa !important;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-bottom: none;
}
#header .header-home-add {
  display: none;
}



.container {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 20px;

}

.comment-box {
  background: black;
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.comment-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}

.form-group {
  margin: 10px;
  
}

label {
  display: block;
  font-size: 16px;
}

 #userComment {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  outline: none;
  background: #333;
  color: white;
  
}

#submitComment {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background: #fee900;
  color: black;
  font-size: 16px;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}


.com{
  display: flex;
  gap: 10px;
}

/* Define a style for the comment card */
.comment-card {
  width: 98%;
  margin: 10px auto;
  padding: 15px;
  border: 1px solid gray;
  border-radius: 10px;
  font-family: Arial, sans-serif;
}

.comment-header {
  display: flex;
  margin-bottom: -20px;
}

.comment-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.comment-info {
  display: block;
  justify-content: center;
  align-items: center;
  
}

.selected-episode {
  background-color: #ffaa00; /* Highlight color */
  color: white;
}


.comment-name {
  font-weight: bold;
  font-size: 15px;
  transform: translateY(10px);
}

.comment-date {
  font-size: 12px;
  color: gray;
  transform: translateY(-10px);
}

.comment-body {
  margin-top: 0px;
  margin-left: 55px;
}

.comment-text {
  font-size: 16px;
  line-height: 1.5;
}



@media screen and (max-width:1190px) {
  .player-container {
    display: block;
    flex-direction: column;
   
  }
  
  
  .dropdown-container {

    border-radius: 5px;
    width: 100%;
    margin-left: -2px;
    height: 300px;
    padding: 5px;
    margin-top: 10px;
  
   
  }
  
  .dropdown-container::-webkit-scrollbar{
    display: none;
  }
  
  
  .season-dropdown{
    width: 300px;
    height: 40px;
    background-color: transparent;
    color: white;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
    outline: none;
  }
  
  
  
  .ep-dropdown-ul {
   
    list-style: none;
    margin-left: 0;
    padding: 10;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-template-rows: repeat(2, 1fr); /* 2 rows */
    gap: 10px; /* Add gap between buttons */
    justify-content: center; /* Center the grid */
    align-items: center;
    overflow: auto; /* Enable scrolling if necessary */
  }

  
  .ep-dropdown-li{
    max-height: 120px; /* Set a max height to enable scrolling */
    margin-left: -30px;
    width: 100%;
  }
  
  .ep-dropdown-li button:hover {
    background-color: #444; /* Optional: Highlight button on hover */
  }
  
  .active-episode {
    background-color: #b20000;  /* Active episode button color */
    color: white;
  }
  
  .inactive-episode {
    background-color: transparent; /* Different color for inactive episodes */
    color: lightgray;
  }

  .ep-dropdown-li button{
  
    width: 100%;
  }
  
  .ep-dropdown-li button:hover{
  
    background-color: rgba(116, 0, 0, 0.609);
  }

  
}

@media screen and (max-width: 768px) {
  .player-container {
    display: block;
    flex-direction: column;
  
  }
  
  
  .dropdown-container {
    background-color: black;
    border-radius: 5px;
    width: 100%;
    padding: 5px;
    margin-top: 30px;
   
    margin-bottom: 10px;
    height: 200px;
   
  }
  
  .dropdown-container::-webkit-scrollbar{
    display: none;
  }
  
  
  .season-dropdown{
    width: 100%;
    height: 40px;
    background-color: transparent;
    color: white;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
    outline: none;
  }
  
  
  
  .ep-dropdown-ul {
   
    list-style: none;
    margin-left: 0;
    padding: 0;
    display: flex;
    flex-direction: row; /* Display items in a row */
    flex-wrap: wrap; /* Wrap items to the next line when they exceed the container */
    overflow: auto; /* Use auto for scrolling if necessary */

  }
  
  .ep-dropdown-li{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    max-height: 200px; /* Set a max height to enable scrolling */
  
  }
  .ep-dropdown-li button {
    width: 100%;
     /* Optional: Highlight button on hover */
  }
  
  .active-episode {
    background-color: #b20000;  /* Active episode button color */
    color: white;
  }
  
  .inactive-episode {
    background-color: transparent; /* Different color for inactive episodes */
    color: lightgray;
  }
  
  .ep-dropdown-li button:hover{
  
    background-color: rgba(157, 0, 0, 0.611);
  }

  
}



@media screen and (max-width: 600px) {
  .comment-card {
    width: 100%;
  }
  .com{
    display: block;
    gap: 10px;
  }

  .player-container {
    display: block;
    flex-direction: column;
  }
  
  
  .dropdown-container {
    background-color: black;
    border-radius: 5px;
    width: 100%;
    padding: 5px;
    margin-top: 50px;
    margin-bottom: 10px;
   
  }
  
  .dropdown-container::-webkit-scrollbar{
    display: none;
  }
  
  
  .season-dropdown{
    width: 100%;
    height: 40px;
    background-color: transparent;
    color: white;
    box-shadow: none;
    border: none;
    border-bottom: 1px solid gray;
    margin-bottom: 10px;
    outline: none;
  }
  
  
  
  .ep-dropdown-ul {
   
    list-style: none;
    margin-left: 0;
    padding: 0;
    display: flex;
    flex-direction: row; /* Display items in a row */
    flex-wrap: wrap; /* Wrap items to the next line when they exceed the container */
    overflow: auto; /* Use auto for scrolling if necessary */
    /* Set a max height to enable scrolling */
  }
  
  .ep-dropdown-li{
    width: 100%;
    max-height: 200px; 
    display: flex;
    flex-direction: row;
    justify-content: center;
  
  }
  .ep-dropdown-li button {
    width: 100%;
     /* Optional: Highlight button on hover */
  }
  
  .active-episode {
    background-color: #b20000;  /* Active episode button color */
    color: white;
  }
  
  .inactive-episode {
    background-color: transparent; /* Different color for inactive episodes */
    color: lightgray;
  }
  
  .ep-dropdown-li button:hover{
  
    background-color: rgba(157, 0, 0, 0.611);
  }

  .comment-name {
    font-weight: bold;
    font-size: 12px;
    transform: translateY(7px);
  }
  
  .comment-date {
    font-size: 10px;
    color: gray;
    transform: translateY(-7px);
  }

  .comment-header {
    display: flex;
    margin-bottom: -10px;
  }

  .comment-text {
    font-size: 14px;
    line-height: 1.2;
  }
  
}

@media (min-width: 768px) {
  input, #userComment {
    width: 70%;
  }

  #submitComment {
    width: 30%;
  }

  
}



@media screen and (min-width: 1200px) {
  #header.header-home-fix {
    position: relative;
    height: auto;
    text-align: center;
    margin-bottom: 80px;
  }

  #header.header-home-fix #logo {
    position: absolute;
    float: none;
    top: 0;
    left: 15px;
    margin-right: 0;
  }
  #header.header-home-fix #search {
    display: none;
  }
  #header.header-home-fix .btn-on-header {
    background: #fff;
    color: #111 !important;
    height: 35px;
    margin: 5px 0;
    padding: 0 10px;
    line-height: 35px;
  }
  #header .btn-on-header.btn-login {
    font-size: 1em;
  }
  #header.header-home-fix .btn-on-header.btn-login i {
    font-size: 14px;
    margin-right: 10px !important;
  }
  #header.header-home-fix #header_menu {
    float: none;
    display: inline-block;
    margin: 0 auto;
  }
  #header.header-home-fix .header-home-add {
    position: relative;
    margin: 60px 0 0;
    text-align: center;
    clear: both;
    width: 100%;
    display: inline-block;
  }
  #header.header-home-fix #search-home {
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 980px;
  }
  #header.header-home-fix #search-home .search-content {
    position: relative;
    margin-right: 80px;
    bottom: -35px;
  }
  #header.header-home-fix #search-home .search-content input.search-input {
    height: 70px;
    margin: 0;
    border-radius: 45px;
    padding-right: 85px;
    background: #fff;
    color: #cfcfcf;
    border: none;
    font-size: 1.8em;
    font-weight: 300;
    padding-left: 65px;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.05) !important;
  }
  #header.header-home-fix
    #search-home
    .search-content
    input.search-input:focus {
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1) !important;
  }
  #header.header-home-fix #search-home .search-content .btn-primary-submit,
  #header.header-home-fix #search-home .search-content .search-icon {
    width: 70px;
    height: 70px;
    line-height: 70px;
    font-size: 20px;
    color: #111;
  }
  #header.header-home-fix #search-home .search-content .btn-primary-submit {
    position: absolute;
    top: 0;
    right: -80px;
    bottom: 0;
    z-index: 9;
    padding: 0;
    border-radius: 35px;
  }
  #header.header-home-fix #search-home .search-content .search-result-pop {
    top: 75px;
    border-radius: 10px;
    padding: 10px 0 0;
    overflow: hidden;
  }
  #header.header-home-fix .sht-heading {
    margin-bottom: 0 !important;
  }
}
.sht-heading {
  font-size: 2.2em;
  font-weight: 300;
  margin: 0 0 30px;
  color: #fff;
}
.sht-heading strong {
  font-weight: 600;
}
#main-wrapper {
  margin-bottom: 70px;
  min-height: calc(100vh - 326px);
}
.bah-setting .btn,
.btn-in-headcat {
  margin: 4px 0;
}
#suggest {
  margin-bottom: 30px;
}
.category_filter .category_filter-content {
  padding: 20px;
  background: #fff;
  border: 1px dashed #aaa;
  margin-bottom: 20px;
}
.category_filter .category_filter-content .cfc-item {
  display: block;
  padding: 0 0 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
  width: 100%;
  position: relative;
  padding-left: 80px;
}
.category_filter .category_filter-content .cfc-item.end {
  border-bottom: none;
}
.category_filter .category_filter-content .cfc-item .ni-head {
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: 0;
}
.category_filter .category_filter-content .ni-list .custom-control-label {
  line-height: 1.6rem;
  cursor: pointer;
}
.category_filter .category_filter-content .cfc-button .btn {
  padding-left: 15px;
  padding-right: 15px;
}
.category_filter .bah-setting-mobile {
  margin-bottom: 15px;
  display: none;
}
.category_filter .bah-setting-searchpage {
  display: block;
}
.block_area {
  display: block;
  margin-bottom: 80px;
}
.block_area .block_area-header {
  margin-bottom: 15px;
  display: block;
  width: 100%;
}
.block_area .block_area-header .cat-heading {
  font-size: 30px !important;
  line-height: 1.3em;
  font-weight: 300;
  padding: 0;
  margin: 0;
}
.block_area .block_area-header .viewmore .btn {
  padding: 4px 0;
}
.block_area .block_area-header-tabs {
  margin-bottom: 25px;
}
.block_area .block_area-header-tabs .pre-tabs {
  border-bottom: none;
  margin-top: 5px;
}
.block_area .block_area-header-tabs .pre-tabs .nav-item {
  margin-bottom: 0;
  margin-right: 5px;
}
.block_area_home .category_filter {
  margin-top: -10px;
}
.block_area_home .btn-in-headcat {
  margin: 2px 0;
}
.pre-tabs {
  border-bottom: 2px solid #3e8afa;
}
.pre-tabs .nav-item {
  font-size: 16px;
  font-weight: 400;
  margin-right: 5px;
}
.pre-tabs .nav-item .nav-link {
  padding: 7px 10px;
  line-height: 1.2em;
  font-size: 14px;
  color: #111;
  background: #f5f6f7;
  border: none;
  border-radius: 3px;
}
.pre-tabs .nav-item .nav-link:hover {
  border-bottom-color: #222;
}
.pre-tabs .nav-item .nav-link.active {
  background: #3e8afa;
  color: #fff;
}
.film-poster-ahref i,
.film-poster-ahref:before {
  position: absolute;
  top: 50%;
  left: 50%;
}
.film_list {
  display: block;
}
.film_list .film_list-wrap {
  margin: 0 -0.25%;
}
.film_list .film_list-wrap .flw-item {
  width: 12%;
  margin: 0 0.25% 10px;
  float: left;
}
.film_list-grid-big .film_list-wrap .flw-item {
  width: 15.66%;
}
.flw-item .film-poster .film-poster-ahref {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: inline-block;
}
.flw-item .film-poster .film-poster-ahref:after {
  content: "";
  position: absolute;
  top: auto;
  right: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  opacity: 0;
  background: rgba(22, 22, 22, 0.5);
  z-index: 1;
}
.flw-item .film-poster .film-poster-ahref:before {
  content: "";
  width: 60px;
  height: 60px;
  margin-top: -30px;
  margin-left: -30px;
  border-radius: 50%;
  background: #3e8afa;
  opacity: 0;
  z-index: 2;
}
.flw-item .film-poster .film-poster-ahref i {
  font-size: 20px;
  line-height: 20px;
  width: 2px;
  margin-top: -10px;
  margin-left: -7px;
  color: #fff;
  z-index: 2;
  opacity: 0;
}
.flw-item .film-poster {
  margin-bottom: 10px;
}
.flw-item .film-poster:hover .film-poster-ahref:after {
  height: 100%;
  opacity: 1;
}
.flw-item .film-poster:hover .film-poster-ahref:before {
  opacity: 1;
}
.flw-item .film-poster:hover .film-poster-ahref i {
  opacity: 1;
}
.flw-item .pick {
  position: absolute;
  z-index: 5;
  top: 10px;
  right: 10px;
  font-size: 14px;
  font-family: arial;
  font-weight: 600;
  display: inline-block;
  padding: 3px 4px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.95);
}
.flw-item .pick.film-poster-quality {
  color: #111;
  background: #fff;
}
.flw-item .pick.film-poster-eps {
  right: auto;
  left: 10px;
  color: #111;
}
.flw-item .pick.film-remove-fav {
  top: 0;
  left: 0;
  border-radius: 0;
  text-align: center;
  width: 30px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  background: #ac0b0d;
  color: #fff;
}
.flw-item .pick.film-remove-fav i {
  line-height: 1em;
  overflow: hidden;
}
.film_list .film_list-wrap .flw-item .film-detail {
  font-size: 0.95em;
  color: #666;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px 12px 12px;
  z-index: 2;
  background: #000;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0,
    rgba(13, 14, 15, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0) 0,
    rgba(13, 14, 15, 0.8) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0,
    rgba(13, 14, 15, 0.8) 100%
  );
}
.film_list .film_list-wrap .flw-item .film-detail .film-name {
  font-size: 14px;
  line-height: 1.3em;
  margin: 0;
}
.film_list .film_list-wrap .flw-item .film-detail .film-infor {
  margin-bottom: 2px;
}
.pre-pagination .pagination .page-item {
  margin: 5px;
}
.pre-pagination .pagination .page-item .page-link {
  border-radius: 20px;
  border: none;
  background: 0 0;
  color: #111;
  font-weight: 400;
  line-height: 1em;
}
.pre-pagination .pagination .page-item .page-link:hover {
  background: 0 0;
  color: #3e8afa;
}
.pre-pagination .pagination .page-item.active .page-link {
  background: #3e8afa;
  color: #fff;
  cursor: default;
}
.pre-pagination .pagination-lg .page-item .page-link {
  padding: 0 10px;
  line-height: 40px;
  font-size: 16px;
  border-radius: 20px;
  min-width: 40px;
  text-align: center;
}
.modal-backdrop.show {
  background: #111;
  opacity: 0.9;
}
.premodal .modal-content {
  border-radius: 0;
  border: none;
  font-family: arial;
}
.premodal-login .modal-content {
  background: #fff;
  color: #111 !important;
}
.premodal .modal-content .modal-header {
  border-bottom: 0;
  border-radius: 0;
  padding: 30px 30px 0;
  position: relative;
  display: block;
}
.premodal .modal-content .modal-header .modal-title {
  text-align: center;
  font-weight: 600;
}
.premodal .modal-content .close {
  position: absolute;
  top: -15px;
  right: -15px;
  color: #111;
  margin: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  z-index: 3;
  text-align: center;
  line-height: 30px;
  display: inline-block;
  padding: 0;
  opacity: 1;
  text-shadow: none;
}
.premodal .modal-content .close:hover {
  opacity: 1;
  background: #3e8afa;
  color: #fff;
}
.premodal .modal-content .modal-body {
  padding: 20px 30px;
}
.premodal-login .modal-content .modal-body {
  padding: 20px 60px;
}
.premodal-trailer .modal-content .modal-body {
  padding: 5px;
}
.premodal-trailer .modal-dialog {
  max-width: 800px;
}
.premodal .modal-content .modal-footer {
  padding: 30px;
  display: block;
  background: #eee;
  border-top: none;
}
.preform .form-group {
  margin-bottom: 20px;
}
.preform .form-control {
  font-size: 13px;
  border-radius: 0;
}
.preform .prelabel {
  font-size: 11px;
  text-transform: uppercase;
  opacity: 0.5;
  font-weight: 400;
  letter-spacing: 1px;
}
.preform .custom-control-label {
  line-height: 1.5rem;
}
.text-forgot {
  line-height: 1.5rem;
}
.link-highlight {
  color: #3e8afa !important;
  cursor: pointer;
}
.modal-logo {
  text-align: center;
}
.modal-logo img {
  height: 40px;
  width: auto;
}
.premodal .modal-logo {
  margin-top: -10px;
}
.premodal .alert {
  font-size: 13px;
  line-height: 1.4em;
  border-radius: 0;
}
.premodal .btn {
  padding: 0.5rem 0.75rem;
}
#sidebar_menu_bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(28, 28, 28, 0.6);
  z-index: 100;
  display: none;
}
#sidebar_menu_bg.active {
  display: block;
}
#sidebar_menu {
  position: fixed;
  z-index: 101;
  left: -400px;
  opacity: 0;
  top: 0;
  bottom: 0;
  overflow-y: auto;
  width: 300px;
  padding: 20px;
  background: #111;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  color: #ccc;
}
#sidebar_menu.active {
  left: 0;
  opacity: 1;
}
#sidebar_menu a {
  color: #ccc;
}
#sidebar_menu a:hover {
  color: #fff;
}
#sidebar_menu .sidebar_menu-list {
  font-size: 16px;
  margin-top: 10px;
}
#sidebar_menu .sidebar_menu-list > .nav-item {
  display: block;
  width: 100%;
  border-bottom: 1px solid #222;
  position: relative;
}
#sidebar_menu .sidebar_menu-list > .nav-item > .nav-link {
  display: block;
  padding: 15px 0;
  color: #fff;
}
#sidebar_menu .sidebar_menu-list > .nav-item > .toggle-submenu {
  position: absolute;
  top: 5px;
  right: -10px;
  padding: 10px;
  z-index: 3;
  cursor: pointer;
}
#sidebar_menu .sidebar_menu-list > .nav-item > .toggle-submenu:hover {
  color: #3e8afa;
}
#sidebar_menu .sidebar_menu-list > .nav-item .nav {
  margin-bottom: 20px;
}
#sidebar_menu .sidebar_menu-list > .nav-item .nav > .nav-item {
  display: block;
  width: 49%;
  margin-right: 1%;
  float: left;
}
#sidebar_menu .sidebar_menu-list > .nav-item .nav > .nav-item > .nav-link {
  font-size: 14px;
  padding: 10px 0;
  color: #aaa;
}
.loading {
  position: absolute;
  top: 50%;
  margin-top: -9px;
  left: 50%;
  margin-left: -30px;
}
.loading > div {
  width: 18px;
  height: 18px;
  background-color: #aaa;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.loading .span1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loading .span2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  100%,
  80% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.loading-relative {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 50px;
}
.film-poster .loading-relative,
.watching_player-area .loading-relative {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.1);
}
.search-result-pop .loading-relative {
  min-height: 160px;
}
.bah-filter .btn-in-headcat {
  border-radius: 3px;
  font-size: 14px;
  line-height: 1em;
  padding: 7px 10px;
  margin: 5px 0 0;
  border: none;
}
.bah-filter .btn-in-headcat i {
  font-size: 12px;
}
.film_list .film_list-wrap .flw-item {
  margin-bottom: 15px;
}
.film_list .film_list-wrap .flw-item .film-detail-fix {
  position: relative;
  bottom: auto;
  left: auto;
  right: auto;
  margin-bottom: 10px;
  text-align: left;
  background: 0 0;
  padding: 0;
  padding-right: 10px;
}
.film_list .film_list-wrap .flw-item .film-detail-fix .film-name {
  margin-bottom: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
.film_list .film_list-wrap .flw-item .film-detail-fix .fd-infor {
  line-height: 1.3em;
}
.film_list .film_list-wrap .flw-item .film-detail-fix .fd-infor .dot {
  margin: 3px 5px;
}
.film_list .film_list-wrap .flw-item .film-detail-fix .fd-infor .fdi-type {
  font-size: 0.9em;
  line-height: 1em;
  padding: 2px 4px;
  border: 1px solid #aaa;
  border-radius: 3px;
}
.film_list .film_list-wrap .flw-item .film-poster {
  margin-bottom: 10px;
}
#footer {
  background: #000;
  padding: 40px 0;
  color: #b9b9b9;
  position: relative;
}
#footer a {
  color: #b9b9b9;
}
#footer a:hover {
  color: #e62e05;
}
#footer .container {
  position: relative;
  padding-left: 260px;
  z-index: 3;
}
#footer .footer-about {
  float: left;
  width: 650px;
}
#footer .footer-about .footer-fa-text {
  margin-bottom: 15px;
  line-height: 1.3em;
}
#footer .footer-about .footer-fa-menu a {
  color: #fff;
}
#footer .footer-about .footer-fa-menu .space {
  display: inline-block;
  margin: 0 10px;
  opacity: 0.5;
}
#footer .footer-block {
  float: left;
  margin-right: 60px;
}
#footer .footer-logo-block {
  position: absolute;
  top: 0;
  left: 15px;
}
#footer .footer-logo-block .footer-logo {
  display: inline-block;
  margin-bottom: 20px;
}
#footer .footer-logo-block .footer-logo img {
  height: 46px;
  width: auto;
}
#footer .footer-links-wrap {
  float: left;
}
#footer .footer-links ul {
  list-style: none;
}
#footer .footer-links ul li {
  margin-bottom: 10px;
}
#footer .footer-notice {
  float: right;
}
#footer .footer-notice span {
  display: inline-block;
  padding: 10px;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 340px;
  font-size: 12px;
  line-height: 1.3em;
  font-weight: 300;
  border: 1px solid #393939;
  text-align: justify;
  position: relative;
}
#footer .footer-notice span:before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 50%;
  background: #e62e05;
  top: -5px;
  right: -5px;
}
.fa-icon-cat {
  font-size: 16px;
}
.detail_page .breadcrumb {
  color: #fff;
}
.detail_page .breadcrumb-item a {
  color: #fff;
}
.detail_page .breadcrumb-item.active,
.detail_page .breadcrumb-item:before {
  color: #fff !important;
}
.detail_page.detail_page-style {
  margin-top: -25px;
  padding-top: 25px;
  position: relative;
}
.detail_page.detail_page-style .cover_follow {
  background-position: 50% 25%;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  max-height: 800px;
  z-index: 1;
}
.detail_page.detail_page-style .cover_follow:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(11, 11, 11, 0.6);
}
.detail_page.detail_page-style .container {
  z-index: 4;
  position: relative;
}
.detail_page-watch {
  position: relative;
}
.detail_page-watch .dp-w-cover {
  position: relative;
  width: 100%;
  padding-bottom: 40%;
  left: 0;
}
.detail_page-watch .dp-w-cover .dp-w-c-play {
  background: #3e8afa;
  color: #fff;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  display: inline-block;
  z-index: 2;
}
.detail_page-watch .dp-w-cover .dp-w-c-play:hover {
  background: #3e8afa;
  color: #fff;
}
.detail_page-watch .dp-w-cover .dp-w-c-play i {
  line-height: 100px;
  font-size: 40px;
  position: relative;
  left: 5px;
}
.detail_page-watch .detail_page-infor {
  font-size: 15px;
  z-index: 5;
  position: relative;
  line-height: 1.3em;
  font-weight: 300;
  margin: 20px 0;
}
.detail_page-style .detail_page-watch .detail_page-infor {
  min-height: 390px;
  margin: 0 0 30px;
  padding: 0;
  background: 0 0;
}
.detail_page-watch .detail_page-infor .dp-i-content {
  position: relative;
}
.detail_page .detail_page-infor .dp-i-content {
  padding: 30px;
  border-radius: 20px;
  background-color: black;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  min-height: 410px;
}
.detail_page-watch .btn-imdb {
  background: 0 0 !important;
  display: flex;
  gap: 10px;
  color: #ffa600 !important;
  font-weight: 600;
  border-color: transparent !important;
}
.watch_page-tv .detail_page-infor .dp-i-content {
  margin-bottom: 30px !important;
}
.dps-spacing {
  margin: 0;
  height: 20px;
  display: block;
}
.heading-name {
  font-size: 2.5em;
  line-height: 1.3em;
  margin: 0 0 15px;
  font-weight: 300;
}
.heading-name button{
  background-color: transparent;
  color: white;
  border: none;
  font-size: 30px;
  margin-left: 20px;
}
.dp-i-c-stick {
  margin-bottom: 15px;
}
.dp-i-c-stick .btn i {
  font-size: 0.8em;
}
.detail_page.detail_page-style
  .detail_page-watch
  .detail_page-infor
  a.btn-light {
  color: #111 !important;
}
.detail_page .detail_page-watch .detail_page-infor .description {
  margin-bottom: 15px;
  line-height: 1.5em;
}
.detail_page-watch .detail_page-infor .dp-i-stats {
  margin-bottom: 15px;
}
.detail_page .dp-i-c-poster {
  width: 180px;
  position: absolute;
  top: 30px;

  left: 30px;
}
.detail_page .dp-i-c-right {
  padding-left: 210px;
  padding-right: 0;
}
.dp-i-c-right .elements {
  margin-bottom: 20px;
}
.dp-i-c-right .elements .row-line {
  margin-bottom: 5px;
}
.btn-xs {
  font-size: 12px;
  padding: 2px 4px;
  line-height: 1em;
}
.btn-xs i {
  font-size: 12px;
}
.btn-rating {
  position: relative;
}
.detail_page.detail_page-style .block-rating {
  margin-top: 15px;
}
.block-rating .rating-result {
  margin-bottom: 15px;
}
.block-rating .rating-result .rr-mark {
  margin-bottom: 5px;
  font-family: arial;
  font-size: 12px;
}
.detail_page.detail_page-style .block-rating .rating-result .rr-mark {
  color: #111;
}
.block-rating .rating-result .rr-mark span {
  font-weight: 600;
  font-size: 16px;
  margin-right: 5px;
}
.detail_page.detail_page-style .block-rating .rating-result .rr-mark span {
  color: #3e8afa;
}
.block-rating .rating-result .progress {
  height: 3px;
  background: #333;
}
.block-rating .rating-result .progress .bg-success {
  background: #3e8afa !important;
}
.block-rating button {
  width: 48%;
  border-radius: 2px;
  border: none;
  box-shadow: none !important;
}
.block-rating button.btn-focus {
  background: #3e8afa !important;
  color: #fff !important;
}
.block-rating button.btn-secondary {
  background: #444 !important;
  color: #fff;
}
.watching_player-area {
  width: 100%;
  background: #111;
  position: relative;
  z-index: 1;
}
.watching_player-area.active {
  z-index: 1;
}
.watching_player-area #media-player,
.watching_player-area iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  z-index: 5;
}
.watching_player-control {
  background: #000;
  padding: 10px;
  color: #ccc;
}
.watching_player-control a {
  color: #ccc;
}
.watching_player-control .btn {
  background: 0 0;
  border: none;
  color: #eee;
}
.watching_player-control .btn.active {
  background: rgba(255, 255, 255, 0.2) !important;
  color: #ccc !important;
}
#turn-off-light:after {
  content: "Turn off light";
}
#turn-off-light.active:after {
  content: "Turn on light";
}
#turn-off-light.active {
  position: relative;
  z-index: 100;
  color: #fff !important;
}
#mask-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  background: rgba(11, 11, 11, 0.98);
  display: none;
}
#mask-overlay.active {
  display: block;
}
.detail_page-servers {
  background: #fff;
}
.detail_page-servers .dp-s-line {
  padding: 15px;
  position: relative;
  margin: 1px 0;
}
.detail_page-servers .dp-s-line .nav {
  display: block;
  text-align: center;
}
.detail_page-servers .dp-s-line span.name {
  position: absolute;
  top: 30px;
  left: 25px;
  font-weight: 600;
}
.detail_page-servers .dp-s-line .nav .nav-item {
  margin: 5px;
  display: inline-block;
}
.detail_page-servers .dp-s-line .nav .nav-item .nav-link {
  border-radius: 2px;
  background: #eee;
  font-size: 14px;
  color: #111;
  border: none !important;
  text-align: center;
}
.detail_page-servers .dp-s-line .nav .nav-item .nav-link:hover {
  color: #3e8afa;
}
.detail_page-servers .dp-s-line .nav .nav-item .nav-link.active {
  background: #3e8afa;
  color: #fff;
}
.film_tools {
  display: block;
}
.film_tools .film_tools-item {
  padding: 10px 0;
}
.film_tools .film_tools-item .alert {
  margin-bottom: 0;
}
#box-share {
  text-align: center;
  font-size: 16px;
  padding-top: 15px;
  padding-bottom: 30px;
}
#box-share .bs-name {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
#box-share .btn-light {
  background: #e2e6ea;
}
.information_page .h2-heading,
.information_page .h3-heading,
.information_page .h4-heading {
  font-size: 2em;
  line-height: 1.3em;
  margin-bottom: 10px;
}
.information_page .h3-heading {
  font-size: 1.5em;
}
.information_page .h4-heading {
  font-size: 1.2em;
}
.information_page p {
  line-height: 1.5em;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 1em;
}
.prebreadcrumb .breadcrumb {
  padding: 0;
  background: 0 0;
}
.form-control-textarea {
  max-width: 100%;
  min-width: 100%;
}
.block_area_boxed {
  min-height: calc(100vh - 500px);
}
.block_area_boxed .block_area-header-tabs {
  margin-bottom: 0;
  border-bottom: none;
}
.block_area_boxed .block_area-header-tabs .pre-tabs {
  margin-bottom: 0;
}
.block_area_boxed .block_area-header-tabs .pre-tabs .nav-link {
  background: #e0e0e0;
  color: #333;
  margin-right: 5px;
}
.block_area_boxed .block_area-content {
  background: #fff;
  padding: 30px 50px 50px;
}
.block_area_manager .btn-lg {
  border-radius: 3px;
  font-size: 16px;
}
.block_area_manager .block_area-header-tabs .pre-tabs .nav-link {
  border-radius: 2px 2px 0 0;
}
.block_area_manager .block_area-header-tabs .pre-tabs .nav-link.active {
  background: #3e8afa;
  color: #fff;
}
.block_area_manager .block_area-header-tabs .pre-tabs .nav-item {
  margin-right: 2px;
}
.profile-avatar img {
  height: 120px;
  width: auto;
}
.block_area_manager .list-group-item {
  background: 0 0;
  border-color: #eee;
  color: #666;
}
.blank_page {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.container-404 {
  margin: 200px 0;
}
.container-404 .c4-big {
  font-size: 120px;
  font-weight: 600;
  color: #ddd;
  line-height: 1em;
  margin-bottom: 20px;
}
.container-404 .c4-medium {
  font-size: 30px;
  font-weight: 300;
  line-height: 1.2em;
  margin-bottom: 10px;
}
.container-404 .c4-small {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3em;
  margin-bottom: 30px;
}
.ulclear,
.ulclear li {
  list-style: none;
  margin: 0;
  padding: 0;
}
.seasons-list {
  margin-bottom: 30px;
  font-size: 1.1em;
  line-height: 1.2em;
  background: #222;
}
.seasons-list .sl-content {
  padding: 30px;
}
.seasons-list .sl-content .slc-seasons {
  float: left;
  width: 18%;
}
.seasons-list .sl-content .slc-ul li {
  display: block;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  padding: 2px 0;
}
.seasons-list .sl-content .slc-ul li a {
  padding: 10px 0;
  display: block;
}
.seasons-list .sl-content .slc-ul li.active a {
  color: #3e8afa;
}
.seasons-list .sl-content .slc-seasons .slcs-ul li {
  display: block;
  margin: 0 0 5px;
}
.seasons-list .sl-content .slc-seasons .slcs-ul li a {
  font-weight: 600;
  padding: 12px 20px;
  height: 43px;
  display: block;
  background: rgba(22, 22, 22, 0.05);
  border-radius: 3px;
}
.seasons-list .sl-content .slc-seasons .slcs-ul li.active {
  border-bottom-color: rgba(255, 255, 255, 0);
}
.seasons-list .sl-content .slc-seasons .slcs-ul li.active a {
  background: #3e8afa;
  color: #fff;
}
.seasons-list .sl-content .slc-seasons .slcs-ul li.active .badge {
  background: #fff !important;
  color: #111 !important;
}
.seasons-list .sl-content .sl-title {
  font-size: 1.4em;
  line-height: 1em;
  font-weight: 300;
  margin-bottom: 20px;
}
.seasons-list .sl-content .sl-title i {
  font-size: 14px;
  position: relative;
  top: -2px;
}
.seasons-list .sl-content .slc-eps {
  float: left;
  width: 82%;
  padding-left: 30px;
}
.seasons-list .sl-content .slc-eps .sl-title {
  float: left;
  margin-right: 20px;
}
.seasons-list .sl-content .slc-eps .slce-server {
  display: inline-block;
}
.seasons-list .sl-content .slc-eps .slce-server ul {
  border: 1px solid #fff;
  border-radius: 3px;
  position: relative;
  top: -5px;
  margin-bottom: 0 !important;
}
.seasons-list .sl-content .slc-eps .slce-server ul li a {
  padding: 8px 10px !important;
  color: #111;
  font-size: 12px;
  line-height: 1em;
  border-radius: 0 !important;
}
.seasons-list .sl-content .slc-eps .slce-server ul li a.active {
  background: #3e8afa !important;
  color: #fff !important;
  border-radius: 3px !important;
}
.seasons-list .sl-content .slc-eps .slce-server ul li a:hover {
  border: none !important;
  margin: 0;
  color: #3e8afa;
}
.seasons-list .sl-content .slc-eps .slce-list ul {
  margin-left: -0.5%;
  margin-right: -0.5%;
}
.seasons-list .sl-content .slc-eps .slce-list li {
  margin: 0 0.5% 5px;
  width: 24%;
  float: left;
}
.seasons-list .sl-content .slc-eps .slce-list .nav-link {
  border-radius: 3px;
  height: 43px;
  background: rgba(22, 22, 22, 0.05);
  text-align: left;
  padding: 12px 20px;
  color: #111;
  border: none !important;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 300;
}
.seasons-list .sl-content .slc-eps .slce-list .nav-link strong {
  font-weight: 600;
}
.seasons-list .sl-content .slc-eps .slce-list .nav-link i {
  opacity: 0.3;
  font-size: 10px;
  margin-right: 15px !important;
  position: relative;
  top: -2px;
}
.seasons-list .sl-content .slc-eps .slce-list .nav-link:hover {
  color: #3e8afa;
}
.seasons-list .sl-content .slc-eps .slce-list .nav-link:hover i {
  opacity: 1;
}
.seasons-list .sl-content .slc-eps .slce-list .nav-link.active {
  background: #3e8afa;
  color: #fff;
}
.seasons-list .sl-content .slc-eps .slce-list .nav-link.active i {
  opacity: 1;
}
.watch_page-tv .seasons-list {
  margin-top: 2px !important;
  margin-bottom: -5px;
  border-radius: 0 0 20px 20px !important;
}
.seasons-list {
  background: #fff !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  position: relative;
  padding: 30px;
  margin: 0 0 20px !important;
}
.seasons-list .sl-content {
  padding: 0 !important;
}
.detail-extend-toggle {
  display: none;
}
.breadcrumb-item + .breadcrumb-item:before,
.breadcrumb-item.active {
  color: #111;
}
.slcs-ul {
  max-height: 400px;
  overflow: auto;
}
.text-home {
  font-weight: 400;
  font-size: 1.15em;
  line-height: 1.4em;
  margin-bottom: 50px;
  color: #555;
}
.text-home a {
  color: #3e8afa;
}
.text-home .btn-expand {
  display: none;
}
.premodal .modal-content {
  color: #111;
}
.premodal .modal-content .modal-body p {
  line-height: 1.4em;
}
.report-list {
  border: 1px solid #ced4da;
  background: #f7f7f7;
  margin-bottom: 20px !important;
  border-radius: 5px;
}
.rl-block {
  padding: 10px 15px;
  border-bottom: 1px solid #ced4da;
}
.rl-block:last-of-type {
  border-bottom: none;
}
.rl-block .rl-title {
  margin-bottom: 10px;
}
.rl-reason {
  margin-bottom: 5px;
}
.rl-reason .custom-control {
  display: inline-block;
  margin-right: 10px;
}
.rl-reason .custom-control label {
  line-height: 24px;
  cursor: pointer;
}
.report-textarea .form-control {
  font-size: 14px;
  line-height: 1.4em;
}
@media screen and (max-width: 479px) {
  .rl-reason .custom-control {
    display: block;
    margin-right: 0;
  }
  .report-btn .btn {
    margin: 0 0 10px 0 !important;
    display: block;
    width: 100%;
  }
}
.detail-tags .btn-dtag {
  border: none !important;
  background: #fff !important;
}
.seasons-list .sl-content .slc-eps .slce-server ul li a {
  white-space: nowrap;
}
.seasons-list .sl-content .slc-seasons .slcs-ul li a {
  position: relative;
  padding-right: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.seasons-list .sl-content .slc-seasons .slcs-ul li a .float-right {
  position: absolute;
  right: 20px;
  top: 12px;
}
@media screen and (max-width: 759px) {
  .seasons-list .sl-content .slc-seasons .slcs-ul li {
    width: auto !important;
    margin-bottom: 10px !important;
  }
}
.server-notice {
  padding: 10px 0 15px;
  font-size: 1.1em;
  line-height: 1.3em;
  opacity: 0.8;
}
.detail_page-servers .dp-s-line .nav .nav-item {
  width: auto !important;
  float: none !important;
}
.detail_page-servers .dp-s-line .nav .nav-item .nav-link {
  width: auto !important;
  text-align: left !important;
  position: relative;
  padding: 10px 20px;
  border-radius: 5px !important;
  padding-top: 30px;
  padding-left: 50px;
}
.detail_page-servers .dp-s-line .nav .nav-item .nav-link span {
  font-weight: 500;
}
.detail_page-servers .dp-s-line .nav .nav-item .nav-link span:before {
  content: "Server";
  position: absolute;
  top: 10px;
  font-weight: 400;
  font-size: 13px;
  opacity: 0.6;
}
.detail_page-servers .dp-s-line .nav .nav-item .nav-link i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
}
@media screen and (max-width: 480px) {
  .detail_page-servers .dp-s-line .nav .nav-item {
    width: 100% !important;
    margin: 3px 0 !important;
  }
  .detail_page-servers .dp-s-line .nav .nav-item .nav-link {
    padding: 12px 20px 12px 50px !important;
  }
  .detail_page-servers .dp-s-line .nav .nav-item .nav-link i {
    font-size: 12px;
  }
  .detail_page-servers .dp-s-line .nav .nav-item .nav-link span:before {
    position: relative;
    top: auto;
    left: auto;
    margin-right: 6px;
    font-size: 1em;
  }
  .server-notice {
    text-align: left !important;
    font-size: 1em;
    padding-top: 0;
  }
}
.seasons-list-new .sl-content {
  padding: 20px;
}
.seasons-list-new .sl-content .slc-eps {
  width: 100% !important;
  float: none !important;
  padding: 0 !important;
}
.seasons-list-new .sl-content .slc-eps .slce-list li {
  width: 19%;
  margin-bottom: 10px;
}
.seasons-list-new .sl-content .sl-title i {
  top: auto;
}
.slt-seasons-dropdown {
  position: relative;
}
.btn-seasons {
  background: 0 0;
  color: #111 !important;
  padding: 5px 0 !important;
  line-height: 1.1em;
  box-shadow: none !important;
}
.dropdown-menu-new {
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
}
.dropdown-menu-new .dropdown-item {
  padding: 12px 20px;
  font-size: 14px;
  line-height: 1em;
  color: #111;
}
.dropdown-menu-new .dropdown-item:hover {
  background: #eee;
}
.dropdown-menu-new .dropdown-item.active {
  background: #3e8afa;
  color: #fff;
}

.dropdown-menu-new {
  max-height: 160px;
  overflow: auto;
}
.grecaptcha-badge {
  display: none;
}


.title {
  font-size: 24px;
  font-weight: bold;
}
.jw-flag-user-inactive .jw-title {
  display: none !important;
}
.jwplayer {
  overflow: visible !important;
}
.detail-tags .btn-dtag {
  background: rgba(255, 255, 255, 0.05);
  color: #888;
  margin-right: 10px;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 12px;
}


@media screen and (max-width: 1199px) {
  #watch-iframe {
    height: 600px !important;

  }
  #mask-player {
    height: 600px !important;
  }
  .comment-name {
    font-weight: bold;
    font-size: 12px;
    transform: translateY(7px);
  }
  
  .comment-date {
    font-size: 10px;
    color: gray;
    transform: translateY(-7px);
  }
}
@media screen and (max-width: 998px) {
  #watch-iframe {
    height: 500px !important;
  }
  #mask-player {
    height: 500px !important;
  }

  .comment-header {
    display: flex;
    margin-bottom: -9px;
  }
}
@media screen and (max-width: 759px) {
  #watch-iframe {
    height: 400px !important;
  }
  #mask-player {
    height: 400px !important;
  }
}
@media screen and (max-width: 639px) {
  #watch-iframe {
    height: 300px !important;
  }
  #mask-player {
    height: 300px !important;
  }
}
.slide-genre-item {
  color: #fff;
}
#watch-player.jw-error {
  padding-bottom: 56.25% !important;
}

.news-iframe {
  width: 100%;
  padding-bottom: 320px;
  position: relative;
}
.news-iframe iframe {
  background: 0 0 !important;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: none !important;
}
@media screen and (max-width: 991px) {
  .news-iframe {
    margin: 0 -20px;
    width: calc(100% + 40px);
    padding-bottom: 260px;
  }
}
.detail_page-servers .dp-s-line {
  margin: 0;
}
.watch_page-tv .seasons-list {
  margin: 0 !important;
}
.detail_page-watch .detail_page-infor .dp-i-stats .item {
  display: inline-block;
  margin-bottom: 10px;
}
.detail_page-watch .detail_page-infor .dp-i-stats {
  margin-bottom: 10px;
}
.dp-i-c-stick .btn-download {
  background: #f7d200 !important;
  color: #111 !important;
}
.dp-i-stats .btn-trailer {
  background: #fff !important;
  color: #111 !important;
  border: 1px solid #111 !important;
}
.dp-i-stats .btn-quality {
  background: #fff !important;
  color: #111 !important;
  border: 1px solid #111 !important;
}
.watching_player-control .btn-download {
  color: #f7d200 !important;
}
@media screen and (min-width: 1681px) {
  .container {
    max-width: 1600px;
  }
}
@media screen and (max-width: 1680px) {
  .container {
    max-width: 1400px;
  }
}
@media screen and (max-width: 1400px) {
  .container {
    max-width: 1200px;
  }
  .film_list .film_list-wrap .flw-item {
    width: 16.16%;
  }
  #header_menu ul.header_menu-list .nav-item {
    margin-right: 0;
  }
  #footer .footer-notice span {
    width: 240px;
    text-align: left;
  }
  #search {
    width: 250px;
  }
}
@media screen and (max-width: 1199px) {
  #header.header-home {
    position: relative;
    left: auto;
    top: auto;
    background: #000;
    margin-bottom: 0;
  }
  #header {
    height: 120px;
    text-align: center;
  }
  #header .container {
    padding-left: 0;
    padding-right: 0;
  }
  #header_menu ul.header_menu-list {
    display: none;
  }
  #header_menu .container:before {
    display: none;
  }
  #header_right {
    position: static;
    top: 0;
  }
  #header_right .btn-on-header {
    height: 60px;
    line-height: 60px;
    border-radius: 0;
  }
  #header_right .btn-on-header.btn-login {
    height: 60px;
    padding: 0;
    width: 60px;
    border-radius: 0;
  }
  #header_right .btn-on-header.btn-login i {
    line-height: 60px;
  }
  #user-slot {
    height: 60px;
    margin-right: 5px;
  }
  #user-slot .logged {
    margin-right: 10px;
    margin-top: 11px;
  }
  #header #search-toggle.active .btn-on-header {
    background: 0 0;
    color: #aceb2f;
  }
  body.body-hidden {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  #mobile_menu {
    top: 0;
    width: 60px;
    text-align: center;
    left: 0;
    display: block;
  }
  #mobile_menu i {
    font-size: 20px;
    line-height: 60px;
    height: 60px;
  }
  #header #logo {
    height: 30px;
    width: 240px;
    text-align: center;
    margin: 15px 0;
    position: absolute;
    font-size: 0;
    left: 50%;
    margin-left: -120px;
  }
  #header #logo img {
    display: inline-block;
    float: none;
    vertical-align: top;
  }
  #header #logo span {
    display: inline-block;
    line-height: 30px;
    float: none;
    font-size: 16px;
  }
  #search {
    top: 60px;
    left: 0;
    bottom: auto;
    width: 100%;
    display: block;
    position: absolute;
    background: #144184;
    padding: 0 15px 15px;
  }
  #search.active {
    right: auto;
    display: block;
  }
  #search .search-result-pop {
    background: #fff;
    left: -15px;
    right: -15px;
  }
  #search .search-result-pop .nav-item {
    padding: 15px;
  }
  #search .search-result-pop .nav-item .film-poster {
    width: 40px;
    padding-bottom: 55px;
  }
  #search .search-result-pop .nav-item .srp-detail {
    padding-top: 5px;
    padding-left: 55px;
  }
  #search .search-result-pop .nav-item .srp-detail .film-name {
    height: 16px;
    line-height: 1.2em;
    font-size: 14px;
  }
  #search .search-result-pop .nav-bottom {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .search-content input.search-input {
    background: #fff !important;
    color: #111;
    border-radius: 5px;
  }
  .search-content .search-icon {
    color: #111;
  }
  .detail_page-watch .detail_page-infor {
    font-size: 14px;
    line-height: 1.3em;
    min-height: 350px;
  }
  .heading-name {
    font-size: 30px;
  }
  .film_related .block_area_category {
    background: 0 0;
    padding: 25px 0 30px;
  }
  #film_comments {
    padding-top: 0;
  }
  .block_area_boxed .block_area-content {
    padding: 20px;
  }
  .qtip {
    display: none !important;
  }
  .detail_page-watch .detail_page-infor {
    min-height: 410px;
  }
  #main-wrapper {
    min-height: calc(100vh - 378px);
  }
  #footer {
    padding: 30px 0;
  }
  #footer .container {
    padding-left: 15px;
  }
  #footer .footer-logo-block {
    position: relative;
    top: auto;
    left: auto;
    display: inline-block;
    overflow: hidden;
    width: 100%;
    margin-top: 30px;
    text-align: left;
  }
  #footer .footer-logo-block .footer-logo {
    display: none;
  }
  #footer .footer-logo-block p {
    margin-bottom: 0 !important;
  }
  .seasons-list .sl-content .slc-seasons {
    width: 23%;
  }
  .seasons-list .sl-content .slc-eps {
    width: 77%;
    padding-left: 20px;
  }
  .seasons-list .sl-content {
    padding: 20px;
  }
  .seasons-list .sl-content .slc-eps .slce-list li {
    width: 32.33%;
  }
  .detail_page.detail_page-style .cover_follow {
    height: 600px;
  }
  .text-home {
    margin-bottom: 30px;
    font-size: 1em;
    line-height: 1.4em;
  }
  .text-home .text-home-main {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .text-home.thm-expand .text-home-main {
    display: block;
    -webkit-line-clamp: none;
  }
  .text-home .btn-expand {
    display: inline-block;
    padding: 5px 8px;
    line-height: 1em;
    background: #e2e6ea !important;
    color: #111 !important;
    border: none !important;
  }
  .text-home .btn-expand i {
    font-size: 12px;
    line-height: 1em;
  }
  .text-home .btn-expand span:before {
    content: "more";
  }
  .text-home.thm-expand .btn-expand span:before {
    content: "less";
  }
  .text-home.thm-expand .btn-expand i:before {
    content: "\f068";
  }
  .text-home .btn-expand:hover {
    background: #fff !important;
    color: #111 !important;
  }
}
@media screen and (max-width: 998px) {
  .film_list .film_list-wrap {
    margin: 0 -0.5%;
  }
  .film_list .film_list-wrap .flw-item {
    width: 24%;
    margin: 0 0.5% 10px;
  }
  #wrapper {
    padding: 0;
  }
  #slider-wrap {
    margin-left: 0;
    margin-right: 0;
  }
  #header {
    margin: 0;
    margin-bottom: 25px;
    padding-left: 0;
    padding-right: 0;
  }
  #header.header-home {
    left: 0;
    right: 0;
  }
  #slider .slide-caption {
    display: none;
  }
  .film_related .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .section-id-01 .block_area-header .cat-heading {
    display: none;
  }
  #footer .footer-about {
    width: 65%;
  }
  #footer .footer-notice {
    width: 30%;
  }
  #footer .footer-notice span {
    width: 100%;
    max-width: none;
  }
  .seasons-list .sl-content .slc-eps .slce-list li {
    width: 49%;
  }
  .block_area .block_area-header-tabs {
    margin-bottom: 20px;
  }
  .detail_page.detail_page-style .cover_follow {
    height: 450px;
  }
}
@media screen and (max-width: 759px) {
  .film_list .film_list-wrap .flw-item {
    width: 32.33% !important;
  }
  .detail_page .dp-i-c-poster {
    width: 140px;
  }
  .detail_page .dp-i-c-right {
    padding-left: 160px;
  }
  .block-rating button {
    float: none !important;
    width: 100%;
    margin: 0 0 5px;
  }
  .detail_page-watch .detail_page-infor .dp-i-stats .item.float-right.ml-3 {
    display: none;
  }
  .detail_page-servers .dp-s-line span.name {
    position: relative;
    top: auto;
    left: auto;
    margin-bottom: 5px;
    display: inline-block;
    width: 100%;
  }
  .detail_page-servers .dp-s-line {
    padding: 15px;
  }
  .block_area_category .block_area-header .bah-setting {
    display: none;
  }
  .category_filter .bah-setting-mobile {
    display: block;
  }
  #turn-off-light {
    display: none;
  }
  .detail_page-servers .dp-s-line ul.nav {
    margin-left: -1%;
    margin-right: -1%;
  }
  .detail_page-servers .dp-s-line ul.nav .nav-item {
    margin: 5px 1%;
  }
  .detail_page-servers .dp-s-line ul.nav .nav-item .nav-link {
    width: 100%;
  }
  .container-404 {
    margin: 100px 0;
  }
  #header {
    margin-bottom: 20px;
  }
  .seasons-list .sl-content .slc-seasons {
    float: none;
    width: 100%;
    margin-bottom: 30px;
    overflow: hidden;
  }
  .seasons-list .sl-content .slc-seasons .slcs-ul {
    margin-left: -1%;
    margin-right: -1%;
  }
  .seasons-list .sl-content .slc-seasons .slcs-ul li {
    float: left;
    width: 18%;
    margin: 0 1% 5px;
  }
  .seasons-list .sl-content .slc-seasons .slcs-ul li .badge {
    display: none;
  }
  .seasons-list .sl-content .slc-seasons .slcs-ul li a {
    padding: 10px 12px;
    height: auto;
  }
  .seasons-list .sl-content .slc-eps {
    width: 100%;
    float: none;
    padding-left: 0;
    position: relative;
  }
  .detail_page.detail_page-style .cover_follow {
    height: 350px;
  }
  .detail_page-watch .dp-w-cover .dp-w-c-play {
    background: #fff;
    color: #111;
  }
}
@media screen and (max-width: 639px) {
  .film_list .film_list-wrap .flw-item {
    width: 49% !important;
  }
  .pre-tabs .nav-item .nav-link {
    font-size: 14px;
  }
  .pre-tabs .nav-item .nav-link i {
    display: none;
  }
  .film_list .film_list-wrap .flw-item .film-detail .film-name {
    font-size: 14px;
    max-height: 36px;
  }
  .header_right-user.logged > .dropdown > .btn-primary {
    font-size: 0;
    width: 30px;
    margin: 5px 0;
    padding: 0;
    line-height: normal;
    border: none;
    background: #fff !important;
    color: #3e8afa !important;
  }
  .header_right-user.logged > .dropdown > .btn-primary i {
    font-size: 20px;
    margin-left: 0 !important;
    line-height: 30px;
  }
  .bah-filter .btn-in-headcat {
    font-size: 0;
    border-radius: 3px !important;
  }
  .bah-filter .btn-in-headcat i {
    margin-right: 0 !important;
  }
  .viewmore .btn {
    font-size: 0;
  }
  .viewmore .btn i {
    font-size: 20px;
  }
  .watch_page .dp-i-c-poster {
    position: relative;
  }
  .detail_page .dp-i-c-right {
    padding: 0;
  }
  .detail_page-watch .dp-w-cover .dp-w-c-play {
    width: 60px;
    height: 60px;
    margin-left: -30px;
    margin-top: -30px;
  }
  .detail_page-watch .dp-w-cover .dp-w-c-play i {
    line-height: 60px;
    font-size: 20px;
    left: 2px;
  }
  .detail_page.detail_page-style .dp-i-c-poster {
    top: -80px;
    left: 5px;
    z-index: 2;
    position: absolute;
  }
  .detail_page.detail_page-style .block-rating .rating-result .rr-mark {
    color: #fff;
  }
  .detail_page.detail_page-style .block-rating .rating-result .rr-mark span {
    color: #fff;
  }
  .block-rating .rating-result .progress {
    background: #fff;
  }
  .detail_page.detail_page-style .dp-i-c-poster .film-poster {
    display: none;
  }
  .detail_page.detail_page-style .dp-i-c-poster .btn {
    display: none;
  }
  .detail_page.detail_page-style .dp-i-c-right {
    padding-right: 0;
  }
  .heading-name {
    font-size: 24px;
    line-height: 1.4em;
  }
  .detail_page.detail_page-style .detail_page-watch {
    margin: 0;
    padding: 0;
  }
  #pc-comment {
    display: none;
  }
  .detail_page.detail_page-style .block-rating {
    margin-top: 15px;
    display: none;
  }
  .watch_page .dp-i-c-poster {
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 10px;
    margin-top: 5px;
    left: auto;
    align-items: center;
  }
  .watch_page .dp-i-c-poster .film-poster {
    width: 200px;
    padding-bottom: 300px;
    float: left;
  }
  .watch_page .dp-i-c-poster .block-rating {
    padding-left: 120px;
    padding-top: 20px;
  }
  .watch_page .dp-i-c-poster .block-rating .rating-result .rr-mark {
    font-size: 20px;
  }
  .watch_page .dp-i-c-poster .block-rating .rating-result .rr-mark span {
    font-size: 40px;
  }
  .watch_page .dp-i-c-right {
    padding-left: 0;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
  }
  .pre-pagination {
    margin: 20px 0 !important;
  }
  .block_area_boxed .block_area-content {
    background: 0 0;
    padding: 20px 0;
  }
  .seasons-list {
    font-size: 14px;
    line-height: 1.2em;
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .detail-extend {
    display: none;
  }
  .detail-extend.active {
    display: block;
  }
  .detail_page-style .detail_page-watch .detail_page-infor {
    min-height: 0;
  }
  .detail_page-tv .dp-i-c-right .heading-name {
    padding-right: 40px;
    position: relative;
  }
  .detail_page-tv .dp-i-c-right .detail-extend-toggle {
    position: absolute;
    display: inline-block;
    top: 85px;
    right: 30px;
    cursor: pointer;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    background: #222;
    color: #fff;
  }
  .detail_page-tv .dp-i-c-right .detail-extend-toggle i {
    line-height: 1em;
  }
  .detail_page-tv.watch_page-tv .dp-i-c-right .detail-extend-toggle {
    top: 10px;
  }
  .detail_page-tv .detail_page-infor .dp-i-content {
    min-height: 0;
  }
  .dp-i-c-right .elements.tags {
    margin-bottom: 0;
  }
  .detail_page-watch .detail_page-infor {
    padding: 0;
    margin: 30px 0 0 !important;
    padding-bottom: 30px;
    border-bottom: 3px solid #000;
  }
  .detail_page-watch .detail_page-infor {
    min-height: 0;
  }
  .prebreadcrumb {
    display: none;
  }
  .dps-spacing {
    margin: 20px 0;
    display: none;
  }
  .block_area .block_area-header .cat-heading {
    font-size: 24px !important;
  }
  .detail_page.detail_page-style .cover_follow {
    height: 400px;
  }
  .detail_page.detail_page-style .cover_follow:after {
    height: 120px;
  }
  .premodal .modal-content .close {
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    top: -40px;
    border-radius: 0;
  }
  .block_area {
    margin-bottom: 40px;
  }
  .watching {
    margin-top: -20px;
  }
  .watching .container {
    padding: 0;
  }
  .detail_page-servers {
    margin: 0 -15px;
    border-radius: 0;
  }
  .watch_page-tv .seasons-list {
    background: #111;
    margin: 0 -15px 20px !important;
  }
  .watch_page .detail_page-infor .dp-i-content {
    background: 0 0;
    padding: 0;
    background: black;
    box-shadow: none !important;
  }
}
@media screen and (max-width: 480px) {
  .swiper-container-horizontal > .swiper-pagination {
    display: none;
  }
  #slider {
    padding-bottom: 50%;
  }
  #slider-wrap {
    margin-bottom: 15px;
  }
  .block_area_manager .block_area-header-tabs {
    display: none;
  }
  .detail_page.detail_page-style
    .detail_page-watch
    .detail_page-infor
    a.btn-light.float-right {
    display: none;
  }
  .detail_page.detail_page-style
    .detail_page-watch
    .detail_page-infor
    a.btn-light.btn-favorite {
    display: none;
  }
  .detail_page-watch .detail_page-infor .dp-i-stats i {
    display: none;
  }
  .detail_page-watch .detail_page-infor .dp-i-stats .item {
    padding-right: 8px;
    border-right: 1px solid #666;
    margin-right: 8px !important;
    white-space: nowrap;
  }
  .premodal-login .modal-content .modal-body {
    padding: 20px 20px;
  }
  .premodal .modal-logo {
    display: none;
  }
  .block_area_manager .notification-list .justify-content-between {
    display: block !important;
  }
  .block_area_manager .notification-list .justify-content-between small {
    margin-bottom: 5px;
    display: inline-block;
    width: 100%;
  }
  .block_area_manager .notification-list .list-group-item {
    box-decoration-break: none;
  }
  #main-wrapper {
    margin-bottom: 30px !important;
  }
  .detail_page-servers .dp-s-line ul.nav {
    max-height: 400px;
    overflow: auto;
  }
  .block_area .block_area-header .cat-heading {
    font-size: 20px !important;
    margin: 6px 0;
  }
  .bah-setting .btn {
    width: 48%;
    margin: 1% !important;
    float: left;
  }
  .bah-setting-mobile {
    overflow: hidden;
  }
  .container-404 {
    margin: 50px 0;
  }
  .container-404 .c4-big {
    font-size: 80px;
    line-height: 1em;
  }
  .container-404 .c4-medium {
    font-size: 18px;
    line-height: 1.2em;
  }
  .container-404 .c4-button .btn {
    display: block;
  }
  #footer .footer-menu .ulclear li {
    margin-right: 0;
  }
  .watching .prebreadcrumb {
    display: none;
  }
  .seasons-list .sl-content .slc-seasons .slcs-ul li {
    width: 31.33%;
  }
  .seasons-list .sl-content .slc-eps .slce-list li {
    width: 98%;
  }
  .seasons-list .sl-content .slc-seasons {
    margin-bottom: 20px;
  }
  .seasons-list .sl-content .slc-seasons .slcs-ul li a {
    border: 1px solid transparent;
  }
  .seasons-list .sl-content .sl-title {
    margin-bottom: 15px;
  }
  .seasons-list .sl-content .slc-eps .slce-list .nav-link {
    height: auto;
    padding: 10px 15px;
  }
  .heading-name {
    font-size: 20px;
  }
}
@media screen and (max-width: 479px) {
  .block_area .block_area-header {
    padding-right: 40px;
    position: relative;
  }
  .block_area .block_area-header .float-right {
    position: absolute;
    top: 0;
    right: 0;
  }
  .bah-filter .btn-in-headcat,
  .pre-tabs .nav-item .nav-link {
    font-size: 12px;
    padding: 0 10px;
    line-height: 30px;
  }
  .block_area .block_area-header-tabs .pre-tabs .nav-item {
    margin-bottom: 5px;
  }
  .bah-filter .btn-in-headcat {
    font-size: 0;
    line-height: 0;
  }
  .bah-filter .btn-in-headcat i {
    line-height: 30px;
    display: inline-block;
  }
  .block_area .block_area-header-tabs {
    border-bottom: none;
    margin-bottom: 10px;
  }
  .block_area_home .category_filter {
    margin-top: -10px;
  }
  .category_filter .category_filter-content {
    padding: 10px;
  }
  .category_filter .category_filter-content .bah-setting i {
    display: none;
  }
  .category_filter .category_filter-content .cfc-item .ni-head {
    position: relative;
    top: auto;
    left: auto;
    display: inline-block;
    width: 100%;
    margin-bottom: 10px;
  }
  .category_filter .category_filter-content .cfc-item {
    padding-left: 0;
  }
  .detail_page.detail_page-style .block-rating {
    display: none;
  }
  .dp-i-c-stick {
    overflow: hidden;
  }
  .dp-i-c-stick .btn:nth-child(1) {
    width: 49%;
    margin-right: 1% !important;
    float: left;
  }
  .dp-i-c-stick .btn:nth-child(2) {
    width: 49%;
    margin-left: 1% !important;
    float: right;
  }
  .detail_page-watch .dp-w-cover {
    padding-bottom: 45%;
  }
  .pre-tabs .nav-item {
    margin-right: 2px;
  }
  #suggest .pre-tabs .nav-item .nav-link {
    margin-right: 0;
  }
  #footer .footer-about,
  #footer .footer-notice {
    float: none;
    width: 100%;
    margin-bottom: 15px;
  }
  #footer .footer-logo-block {
    margin-top: 10px;
  }
  #footer {
    padding: 15px 0;
  }
  .seasons-list .sl-content .slc-eps .slce-server {
    display: block;
    margin-bottom: 15px;
  }
  .seasons-list .sl-content .slc-eps .slce-server ul {
    top: auto;
    float: none;
  }
  .seasons-list .sl-content .slc-eps .sl-title {
    float: none;
    font-size: 1.3em;
    line-height: 1.1em;
  }
  .seasons-list .sl-content .slc-eps .slce-list ul {
    max-height: 400px;
    overflow: auto;
  }
  .detail_page-watch .detail_page-infor .dp-i-stats .item {
    border-right: none;
    padding-right: 0;
    margin-bottom: 5px;
  }
  .detail_page-watch .detail_page-infor .dp-i-stats .item.mr-3 {
    display: none;
  }
  .watch_page-tv .seasons-list {
    padding: 20px;
  }
  .seasons-list .sl-content {
    padding: 0;
  }
  #header #logo img {
    margin-right: 0 !important;
  }
  #header #logo span {
    display: none;
  }
  .detail_page.detail_page-style .cover_follow {
    height: 330px;
  }
  .detail_page-watch .dp-w-cover {
    padding-bottom: 0;
    height: 200px;
  }
  .pre-pagination .pagination {
    display: block;
    height: 44px;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
  }
  .pre-pagination .pagination .page-item {
    margin: 2px;
    display: inline-block;
  }
  .film_list .film_list-wrap .flw-item .film-detail-fix .fd-infor .fdi-type {
    display: none;
  }
  .film_list .film_list-wrap .flw-item {
    width: 46% !important;
    margin: 0 2% 10px;
  }
  .film_list .film_list-wrap {
    margin: 0 -2%;
  }
  .film_list .film_list-wrap .flw-item .film-detail-fix {
    padding-right: 0;
  }
  .detail_page .detail_page-infor .dp-i-content {
    padding: 20px;
  }
  .detail_page.watch_page .detail_page-infor .dp-i-content {
    padding: 0;
  }
  .detail_page-tv .dp-i-c-right .detail-extend-toggle {
    top: 75px;
    right: 20px;
  }
  .seasons-list {
    padding: 20px;
  }
  .seasons-list .sl-content .slc-eps .slce-server ul li a.active {
    background: #3e8afa !important;
    color: #fff !important;
    border-radius: 5px !important;
  }
  .detail_page-servers .dp-s-line ul.nav .nav-item {
    width: 98%;
  }
  .dp-i-c-stick .btn:nth-child(2) {
    margin-right: 0 !important;
  }
  .watching_player-control .btn {
    font-size: 0;
  }
  .watching_player-control .btn i {
    font-size: 20px;
    margin: 0 !important;
  }
  .watching_player-control .btn.btn-download {
    font-size: 14px;
  }
  .watching_player-control .btn.btn-download i {
    font-size: 20px;
    margin-right: 10px !important;
  }
}
@media screen and (max-width: 320px) {
  .detail_page-servers .dp-s-line ul.nav .nav-item {
    margin-bottom: 5px;
  }
}
.premodal-share .modal-content {
  background: #fff;
  color: #111;
  border-radius: 0;
}
.premodal-share.modalshare-1 .modal-content .modal-header {
  /* background-image: url(../images/modal_share-01.png); */
  height: 250px;
  width: 100%;
  background-size: cover;
  background-position: 50% 25%;
}
.premodal-share .modal-content .modal-body {
  padding: 15px 20px;
}
.premodal-share .modal-content .close {
  background: #fff;
  color: #111;
}
.premodal-share.modalshare-2 .modal-content {
  /* background: #4461bc url(../images/modal_share-02-bg.jpg) top center no-repeat; */
  color: #fff;
  border-radius: 20px;
}
.premodal-share.modalshare-2 .modal-content .modal-header {
  height: 180px;
  position: relative;
}
.premodal-share.modalshare-2 .modal-content .modalshare-icon {
  width: 300px;
  height: 270px;
  position: absolute;
  left: 50%;
  margin-left: -150px;
  bottom: 0;
  /* background-image: url(../images/modal_share-02.png); */
  background-size: cover;
  background-position: 50% 25%;
}
.premodal-share.modalshare-3 .modal-content {
  /* background: #e8ddff url(../images/modal_share-03-bg.jpg) top center no-repeat; */
  color: #111;
  border-radius: 20px;
}
.premodal-share.modalshare-2.modalshare-3 .modal-content .modalshare-icon {
  /* background-image: url(../images/modal_share-03.png); */
}
.premodal-share.modalshare-4 .modal-content {
  background: #fff;
  color: #111;
}
.premodal-share.modalshare-1.modalshare-4 .modal-content .modal-header {
  /* background-image: url(../images/modal_share-04.png); */
  height: 250px;
  width: 100%;
  background-size: cover;
  background-position: 50% 25%;
}
.premodal-share.modalshare-4 .modal-title {
  color: #007dfe;
}
@media screen and (max-width: 500px) {
  .premodal .modal-content .close {
    right: 5px;
  }
  .premodal-share.modalshare-2 .modal-content .modal-header {
    height: 120px;
  }
  .premodal-share.modalshare-2 .modal-content .modalshare-icon {
    width: 200px;
    height: 180px;
    margin-left: -100px;
  }
}
.md-share-03 .modal-content {
  border-radius: 20px !important;
  font-size: 14px;
  background: #fff !important;
  color: #111 !important;
  padding: 20px !important;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1) !important;
  border: none !important;
}
.md-share-03 .msc-decor {
  display: block;
  margin: -20px -20px 0;
  width: auto;
}
.md-share-03 .msc-decor-image {
  width: 100%;
  padding-bottom: 50%;
  position: relative;
  display: block;
  border-radius: 18px 18px 0 0;
  overflow: hidden;
}
.md-share-03 .msc-decor-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
}
.md-share-03 .text-close {
  padding: 30px 0 10px;
  font-size: 12px;
  text-decoration: underline;
  color: #888;
  cursor: pointer;
}
.modal-backdrop.show {
  background: #01060f !important;
  opacity: 0.8 !important;
}
.seasons-list .sl-content .slc-eps .slce-server ul li a {
  white-space: nowrap;
}
.seasons-list .sl-content .slc-seasons .slcs-ul li a {
  position: relative;
  padding-right: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.seasons-list .sl-content .slc-seasons .slcs-ul li a .float-right {
  position: absolute;
  right: 20px;
  top: 12px;
}
@media screen and (max-width: 759px) {
  .seasons-list .sl-content .slc-seasons .slcs-ul li {
    width: auto !important;
    margin-bottom: 10px !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1680px) {
  #search {
    width: 200px;
  }
  .search-content .search-result-pop {
    left: auto !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  #header_menu ul.header_menu-list .nav-item > a {
    padding: 0 10px;
    text-transform: unset;
  }
}
