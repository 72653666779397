@use "../../scss/" as *;

.banner-image {
  width: 100%;
  height: 400px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  border-radius: 5px;
  z-index: 1; /* Background and gradient have lower z-index */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include overlay();
  }


}

.banner-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 2;
  color: white;
  height: 100%;
  position: absolute;
  padding: 40px;
  background-color: rgba(255, 0, 0, 0.332);
  border-radius: 5px;
}



.details-container {
  margin-left: 30px;
}

.poster {
  width: 200px; /* Set a fixed width for the poster */
  height: auto;
  object-fit: cover; /* Ensure the image fills its container */
  border-radius: 5px;
}

h1.title {
  font-size: 24px;
  margin-bottom: 10px;
}

p.description {
  font-size: 16px;
  margin-bottom: 20px;
}

.watch-now-button {
  background-color: #ff0000;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    background-color: #cc0000;
  }
}
