@use "./scss/" as *;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap");

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

html {
    font-size: 100%;

    @include tablet {
        font-size: 80%;
    }

    @include mobile {
        font-size: 60%;
    }
}

body {
    font-family: $font-family;
    font-weight: 400;
    line-height: 1.5;

    background-color: $body-bg;
    color: $txt-color;

    @include mobile {
        margin-bottom: 3rem;
    }
}

/* Add this to your App.scss or another appropriate CSS file */
.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .pulsing-logo {
    width: 80px;
    height: 80px;
    background-image: url('../src/assets/logo2.png'); /* Adjust the path as necessary */
    background-size: contain;
    background-repeat: no-repeat;
    animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  

a {
    text-decoration: none;
    color: unset;
}

// @media (hover: hover) {
//     a:hover {
//         color: $main-color;
//     }
// }

button,
input {
    outline: 0;
}

ul {
    list-style-type: none;
}

img {
    max-width: 100%;
}

iframe {
    border: 0;
}

.container {
    max-width: 1660px;
    margin: auto;
}

.mb-3 {
    margin-bottom: 3rem;
}

.mb-2 {
    margin-bottom: 2rem;
}

.section {
    padding: 0 2rem;
}

.section__header {
    @include flex(center, space-between);
    color: white;
}

