.limiter{
    width: 100%;
    margin: 0 auto;
}

.container-login100 {
    width: 100%;
    min-height: 100vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    background: url("../../assets/footer-bg.jpg") no-repeat center;
    background-size: cover;
    
  }
  

  .wrap-login100 {
    width: 390px;
    background: rgba(11, 11, 11, 0.787);
    border-radius: 10px;
    overflow: hidden;
    padding: 77px 55px 33px 55px;
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.535);
    -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.482);
    -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  }

  .login100-form-title {
    display: block;
    font-family: sans-serif;
    font-size: 30px;
    color: red;
    line-height: 1.5;
    text-align: center;
  }
  .login100-form-title i {
    font-size: 60px;
  }
  

  .wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #fff;
    margin-bottom: 37px;
  }
  
  .input100 {
    font-family: sans-serif;
    font-size: 15px;
    color: #fff;
    line-height: 1.2;
    display: block;
    width: 100%;
    height: 45px;
    background: transparent;
    padding: 0 5px;
  }

  .signinBtn{
    width:100%;
    height: 30px;
    border-radius: 10px;
    border: none;
    color: red;
    background: #fff;
    transition: .5s ease;
  }

  .signinBtn:hover{
    color: #fff;
    background: red;
  }


  .btn-show-pass {
    font-size: 15px;
    color: #999999;
  
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    padding-right: 5px;
    cursor: pointer;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  .btn-show-pass:hover {
    color: red;
    color: -webkit-linear-gradient(left, #21d4fd, #b721ff);
    color: -o-linear-gradient(left, #21d4fd, #b721ff);
    color: -moz-linear-gradient(left, #21d4fd, #b721ff);
    color: linear-gradient(left, #21d4fd, #b721ff);
  }
  
  .btn-show-pass.active {
    color: red;
    color: -webkit-linear-gradient(left, #21d4fd, #b721ff);
    color: -o-linear-gradient(left, #21d4fd, #b721ff);
    color: -moz-linear-gradient(left, #21d4fd, #b721ff);
    color: linear-gradient(left, #21d4fd, #b721ff);
  }
  
  .register-form-call{
    margin-top: 20px;
    justify-content: center;
    text-align: center;
    font-weight: 300;
  }

  .registerlink{
    font-weight: 500;
    color: #21d4fd;
  }

  .lost-pass{
    margin-bottom: 20px;
    font-weight: 300;
  }

  /* Signin.css */

  .google-btn{
    justify-content: center;
    display: flex;
  }

.google-sign-in-button {
  background-color: white;
  color: rgb(0, 0, 0);
  border: none;
  width: 300px;
  padding: 10px 25px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 15px;
}

.google-sign-in-button i {
  margin-right: 10px;
}

.google-sign-in-button:hover {
  background-color: rgb(242, 240, 240);
}
