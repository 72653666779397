/* Base Styling */
:root {
   
    --secondary-color: #ffc107;
    --text-color: #ffffff;
    --background-color: #141414;
    --accent-color: #292929;
    --border-radius: 5px;
    --shadow-color: rgba(0, 0, 0, 0.2);
    --font-family: "Roboto", sans-serif;
  }
  
  body {
    font-family: var(--font-family);
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .about-section {
    padding: 60px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .about-container {
    max-width: 900px;
    background: var(--accent-color);
    border-radius: var(--border-radius);
    box-shadow: 0 10px 20px var(--shadow-color);
    padding: 40px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .about-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px var(--shadow-color);
  }
  
  .about-title {
    font-size: 2.8rem;
    font-weight: bold;
    color: red;
    margin-bottom: 20px;
  }
  
  .about-description {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 30px;
  }
  
  .about-highlight {
    margin: 40px 0;
    text-align: left;
    background: black;
    padding: 20px;
    border-radius: var(--border-radius);
  }
  
  .about-highlight h2 {
    font-size: 1.8rem;
    color: white;
    margin-bottom: 15px;
  }
  
  .about-highlight ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    
  }
  
  .about-highlight ul li {
    font-size: 1rem;
    color: white;
    margin: 10px 0;
    padding: 5px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .about-highlight ul li:last-child {
    border-bottom: none;
  }
  
  .about-cta {
    font-size: 1.2rem;
    margin-top: 20px;
  }
  
  .about-cta strong {
   color: red;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .about-title {
      font-size: 2rem;
    }
  
    .about-description, .about-cta {
      font-size: 1rem;
    }
  
    .about-highlight ul li {
      font-size: 0.9rem;
    }
  }
  