@use "../../scss/" as *;

.movie-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 3rem;

  @include tablet {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }

  &__loadmore {
    text-align: center;
  }
}

.movie-search {
  position: relative;
  width: 100%;
  max-width: 500px;

  input {
    width: 100%;
    padding-right: 8rem;
  }

  button {
    position: absolute;
    right: 0;
    top: 2px;

    @include mobile {
      right: 1px;
      top: 1px;
    }
  }

  .suggestions {
    border: 1px solid #ddd;
    max-height: 300px;
    overflow-y: auto;
    background: black;
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  .suggestions .show-container {
    display: flex;
    padding: 5px;
    margin-left: 10px;
    margin-top: 5px;
  }

  .suggestions .show-container img {
    height: 100px;
    margin-right: 20px;
  }
  .suggestion-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background: #f0f0f0;
    }
  }

  .suggestion-image {
    width: 50px;
    height: auto;
    margin-left: 10px;
  }

  .suggestion-title{
    font-style: bold;
    font-size: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .suggestion-overview {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    font-size: 15px;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
