/* Base Styling */
:root {

    --text-color: #ffffff;
    --background-color: #141414;
    --accent-color: #292929;
    --font-family: "Roboto", sans-serif;

  }
  
  body {
    font-family: var(--font-family);
    margin: 0;
    padding: 0;
    background-color: var(--background-color);
    color: var(--text-color);
  }
  
  .terms-section {
    margin-top: 3.5rem;
    padding: 60px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .terms-container {
    max-width: 900px;
    background: var(--accent-color);
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .terms-title {
    font-size: 2.8rem;
    font-weight: bold;
    color: var(--primary-color);
    margin-bottom: 20px;
    text-align: center;
  }
  
  .terms-description {
    font-size: 1.2rem;
    line-height: 1.8;
    margin-bottom: 30px;
  }
  
  h2 {
    font-size: 1.8rem;
    color: var(--primary-color);
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  p, ul {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  li{
    list-style: none;
  }
  
  a {
    color: var(--link-color);
    text-decoration: none;
  }
  

  
  /* Responsive Design */
  @media (max-width: 768px) {
    .terms-title {
      font-size: 2rem;
    }
  
    p, ul {
      font-size: 0.9rem;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  }
  